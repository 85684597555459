import { useState } from "react"
import Spinner from "./Spinner"
import { useAuthContext } from "../context/AuthContext"
import Modal from "./Modal"

const CommentsForm = ({ blog }) => {
  const [comment, setComment] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const { isAuthenticated, user } = useAuthContext()

  function handleChange(event) {
    setComment(event.target.value)
  }

  function handleKeyPress(event) {
    // Check if Enter key is pressed without Shift key
    if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit(event)
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()
    if (comment === "") return
    else if (!isAuthenticated) {
      setModalOpen(true)
      return
    }
    const options = {
      method: "POST",
      headers: {
        "Access-Control-Request-Headers": "*",
      },
      body: JSON.stringify({ owner: user.username, comment, blogId: blog._id }),
    }
    try {
      setIsLoading(true)
      const response = await fetch(
        "https://2n3owzussk.execute-api.us-east-1.amazonaws.com/default/lambdapostcomment-dev",
        options
      )
      if (response.ok) {
        setComment("")
        setIsLoading(false)
        window.location.reload()
      } else {
        console.error("Error sending comment")
        setIsLoading(false)
      }
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner text={"Posting Your Comment"} />
      ) : (
        <div className="font-comfortaa text-primary">
          <Modal
            text="Thank you for your attempt to post a comment. Please sign up or sign in to proceed."
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            button={"Close"}
          />
          <h2 className="my-4 font-bold text-xl lg:text-2xl">Add a Comment:</h2>
          <form onSubmit={handleSubmit} className="flex flex-col">
            <textarea
              placeholder="Enter your comment"
              value={comment}
              onChange={handleChange}
              onKeyDown={handleKeyPress} // To submit the form if clicked on Enter
              id="commentForm"
              name="commentsForm"
              className="w-full p-4 border-b text-forth focus:outline-forth mb-6"
            ></textarea>
            <button
              type="submit"
              className="px-4 py-2 bg-forth text-white rounded font-bold w-[6.5rem] mb-8 active:scale-95 active:bg-gray-200 hover:scale-105"
            >
              Post
            </button>
          </form>
        </div>
      )}
    </>
  )
}

export default CommentsForm
