import { useState } from "react"
import msgImage from "../images/svgs/msg.svg"
import { motion } from "framer-motion"
import EmptyFieldValidator from "../components/form Validators/EmptyFieldValidator"
import EmailValidator from "../components/form Validators/EmailValidator"
import PhoneValidator from "../components/form Validators/PhoneValidator"
import Spinner from "../components/Spinner"
import Modal from "../components/Modal"

const Contact = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isValidFirstName, setIsValidFirstName] = useState(false)
  const [isValidMessage, setIsValidMessage] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const initialFormData = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  }
  const [formData, setFormData] = useState(initialFormData)

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleReset = () => {
    setFormData(initialFormData)
  }

  async function handleSubmit(ev) {
    ev.preventDefault()
    if (
      (isValidFirstName &&
        isValidEmail &&
        isValidMessage &&
        isValidPhoneNumber) ||
      (formData.firstName !== "" &&
        formData.email !== "" &&
        formData.message !== "")
    ) {
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Request-Headers": "*",
        },
        body: JSON.stringify(formData),
      }
      try {
        setIsLoading(true)
        const response = await fetch(
          "https://ikavb6hee0.execute-api.us-east-1.amazonaws.com/default/lambdapostform-dev",
          options
        )
        if (response.ok) {
          handleReset()
          setIsSubmitted(false)
          setIsLoading(false)
          setModalOpen(true)
        } else {
          console.error("Error submitting form")
          setIsLoading(false)
        }
      } catch (error) {
        console.error(error.message)
      }
    } else {
      setIsSubmitted(true)
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner text={"Sending Your Contact Request"} />
      ) : (
        <div className="container flex flex-col justify-center items-center  grow font-comfortaa mb-4 overflow-x-hidden">
          <Modal
            text="Thank You For Contacting Me. I Will Get Back To You As Soon As Possible 🙂"
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
          />
          <h1 className="text-2xl lg:text-3xl text-primary font-bold mt-8 self-start">
            Contact Form
          </h1>
          <motion.div
            initial={{ scale: 1, rotate: 0, opacity: 0 }}
            animate={{ scale: 1.2, rotate: [5, -5, 5, -5, 0], opacity: 1 }}
            transition={{ delay: 0.2, opacity: { duration: 2 } }}
          >
            <img
              src={msgImage}
              alt="contact photo"
              className="shadow-xl mb-16"
            />
          </motion.div>
          <p className="text-forth mb-4 text-center lg:text-lg">
            Thank you for taking the time to explore my portfolio. I hope my
            passion for development shines through in the projects I've shared.
            Let's connect and see how we can create something amazing together!
          </p>
          <form
            onSubmit={handleSubmit}
            className="w-full lg:w-[850px] px-6 py-8 mb-8 rounded-lg shadow-lg self-center"
          >
            <div className="mb-8 flex flex-col gap-6">
              <div>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full py-1 px-2 border-b text-forth focus:outline-forth"
                />
                {isSubmitted && (
                  <EmptyFieldValidator
                    fieldType={"First Name"}
                    content={formData.firstName}
                    setIsValid={setIsValidFirstName}
                  />
                )}
              </div>
              <div>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full py-1 px-2 border-b text-forth focus:outline-forth"
                />
              </div>
              <div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full py-1 px-2 border-b text-forth focus:outline-forth"
                />
                {isSubmitted && (
                  <EmailValidator
                    email={formData.email}
                    setIsValid={setIsValidEmail}
                  />
                )}
              </div>
              <div>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  placeholder="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="w-full py-1 px-2 border-b text-forth focus:outline-forth"
                />
                {isSubmitted && (
                  <PhoneValidator
                    phoneNumber={formData.phoneNumber}
                    setIsValid={setIsValidPhoneNumber}
                  />
                )}
              </div>
              <div>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full py-1 px-2 border-b text-forth focus:outline-forth"
                ></textarea>
                {isSubmitted && (
                  <EmptyFieldValidator
                    fieldType={"Message"}
                    content={formData.message}
                    setIsValid={setIsValidMessage}
                  />
                )}
              </div>
            </div>
            <div className="flex gap-12 mt-4 justify-center">
              <button
                type="submit"
                className="px-4 py-2 bg-primary text-white rounded font-bold w-[6.5rem] active:scale-95 active:bg-gray-200 hover:scale-105"
              >
                Submit
              </button>
              <button
                type="button"
                onClick={handleReset}
                className="px-4 py-2 bg-forth text-white rounded font-bold w-[6.5rem] active:scale-95 active:bg-gray-200 hover:scale-105"
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  )
}

export default Contact
