import { useState } from "react"
import { Auth } from "aws-amplify"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../context/AuthContext"
import { motion } from "framer-motion"
import Spinner from "../components/Spinner"

const SignUpValidation = () => {
  const [code, setCode] = useState("")
  const [isLoading, setIsLoading] = useState("")
  const [notification, setNotification] = useState("")
  const [isCodeSent, setIsCodeSent] = useState(false)
  const { setIsAuthenticated, user } = useAuthContext()
  const navigate = useNavigate()

  function handleChange(ev) {
    setCode(ev.target.value)
  }

  async function handleSubmit(ev) {
    ev.preventDefault()
    try {
      setIsLoading(true)
      await Auth.confirmSignUp(user.username, code) // TODO find the user name
      setIsAuthenticated(true)
      setIsLoading(false)
      navigate("/") // TODO this should take you to the current page
    } catch (error) {
      console.error("code validation failed", error.message)
      setNotification(
        "Invalid Code. Please try again or click on Resend Button"
      )
      setIsLoading(false)
    }
  }

  async function handleResendCode() {
    // In a real application, you would send a new verification code to the user's email here.
    try {
      setIsLoading(true)
      await Auth.resendSignUp(user.username)
      setIsCodeSent(true)
      setNotification("Verification code resent to your email.")
      setIsLoading(false)
    } catch (error) {
      console.error("code resend failed", error.message)
      setNotification("Invalid. please contact me for this issue")
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container flex flex-col grow font-comfortaa my-8 overflow-x-hidden">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 3 }}
          >
            <form
              className="w-full px-6 py-8 rounded-lg shadow-lg self-center"
              onSubmit={handleSubmit}
            >
              <h1 className="text-2xl mt-8 mb-4 text-primary font-bold text-center">
                Enter Verification Code
              </h1>
              <p className="mb-2 text-forth text-center">
                {isCodeSent
                  ? "A verification code has been sent to your email."
                  : "Please check your email for a verification code."}
              </p>
              {notification && (
                <p
                  className={`mb-2 text-center ${
                    notification.startsWith("Invalid")
                      ? "text-red-500"
                      : "text-green-500"
                  }`}
                >
                  {notification}
                </p>
              )}
              <div className="my-8 text-center">
                {/* <label htmlFor="code">Verification Code :</label> */}
                <input
                  type="text"
                  id="code"
                  name="code"
                  value={code}
                  placeholder="Enter Code"
                  onChange={handleChange}
                  className="placeholder:text-center py-1 px-2 border-b text-forth focus:outline-forth text-center w-32"
                />
              </div>
              <div className="flex gap-12 mt-4 justify-center">
                <button
                  type="submit"
                  // onClick={handleConfirm}
                  className="px-4 py-2 bg-primary text-white rounded font-bold w-[6.5rem] active:scale-95 active:bg-gray-200"
                >
                  Confirm
                </button>
                <button
                  type="button"
                  onClick={handleResendCode}
                  className="px-4 py-2 bg-forth text-white rounded font-bold w-[6.5rem] active:scale-95 active:bg-gray-200"
                >
                  Resend
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      )}
    </>
  )
}

export default SignUpValidation
