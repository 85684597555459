import React from 'react'
import "../css/animation.css"

const AnimatedBeat = () => {
  return (
    <svg id="app-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 210.48 193.23">
      <defs>
        <clipPath id="clipPath" transform="translate(-11.36 -10.18)">
        <rect x="10.99" y="9.8" width="211.24" height="193.99" fill="none"/>
        </clipPath>
      </defs>
      <g clipPath="url(#clipPath)">
        <g id="Group_28" data-name="Group 28">
        <path id="Path_26" data-name="Path 26" d="M45.44,203.41H18.18a6.81,6.81,0,0,1-6.82-6.81h0V192a6.81,6.81,0,0,1,6.82-6.81H45.44A6.81,6.81,0,0,1,52.26,192v4.65a6.81,6.81,0,0,1-6.82,6.81h0" transform="translate(-11.36 -10.18)" fill="#e3286c"/>
        <rect id="Rectangle_5" data-name="Rectangle 5" y="174.96" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_27" data-name="Path 27" d="M45.44,180.36H18.18a6.81,6.81,0,0,1-6.82-6.81h0V168.9a6.81,6.81,0,0,1,6.82-6.81H45.44a6.81,6.81,0,0,1,6.82,6.81v4.64a6.82,6.82,0,0,1-6.82,6.82h0" transform="translate(-11.36 -10.18)" fill="#e3286c"/>
        <rect id="Rectangle_6" data-name="Rectangle 6" y="151.91" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_28" data-name="Path 28" d="M45.44,157.3H18.18a6.81,6.81,0,0,1-6.82-6.81h0v-4.65A6.81,6.81,0,0,1,18.18,139H45.44a6.81,6.81,0,0,1,6.82,6.81v4.65a6.81,6.81,0,0,1-6.82,6.81h0" transform="translate(-11.36 -10.18)" fill="#e3286c"/>
        <rect id="Rectangle_7" data-name="Rectangle 7" y="128.85" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_29" data-name="Path 29" d="M45.44,133H18.18a6.81,6.81,0,0,1-6.82-6.81h0v-4.65a6.81,6.81,0,0,1,6.82-6.81H45.44a6.81,6.81,0,0,1,6.82,6.81v4.65A6.81,6.81,0,0,1,45.44,133h0" transform="translate(-11.36 -10.18)" fill="#e3286c"/>
        <rect id="Rectangle_8" data-name="Rectangle 8" y="104.52" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_30" data-name="Path 30" d="M102.8,203.23H75.53a6.81,6.81,0,0,1-6.81-6.81h0v-4.65A6.81,6.81,0,0,1,75.53,185H102.8a6.81,6.81,0,0,1,6.81,6.81v4.64a6.81,6.81,0,0,1-6.81,6.82h0" transform="translate(-11.36 -10.18)" fill="#e54889"/>
        <path id="Path_47" data-name="Path 47" d="M71.18,185h36a2.46,2.46,0,0,1,2.46,2.46v13.35a2.46,2.46,0,0,1-2.46,2.46h-36a2.46,2.46,0,0,1-2.46-2.46V187.42A2.46,2.46,0,0,1,71.18,185Z" transform="translate(-11.36 -10.18)" fill="none"/>
        <path id="Path_31" data-name="Path 31" d="M102.8,180.17H75.53a6.81,6.81,0,0,1-6.81-6.81h0v-4.65a6.81,6.81,0,0,1,6.81-6.81H102.8a6.81,6.81,0,0,1,6.81,6.81h0v4.65a6.81,6.81,0,0,1-6.81,6.81h0" transform="translate(-11.36 -10.18)" fill="#e54889"/>
        <rect id="Rectangle_10" data-name="Rectangle 10" x="57.35" y="151.72" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_32" data-name="Path 32" d="M102.8,157.12H75.53a6.81,6.81,0,0,1-6.81-6.81h0v-4.64a6.81,6.81,0,0,1,6.81-6.81H102.8a6.81,6.81,0,0,1,6.81,6.81v4.64a6.81,6.81,0,0,1-6.81,6.82h0" transform="translate(-11.36 -10.18)" fill="#e54889"/>
        <rect id="Rectangle_11" data-name="Rectangle 11" x="57.35" y="128.67" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_33" data-name="Path 33" d="M102.8,132.79H75.53A6.81,6.81,0,0,1,68.72,126h0v-4.65a6.81,6.81,0,0,1,6.81-6.81H102.8a6.81,6.81,0,0,1,6.81,6.81h0V126a6.81,6.81,0,0,1-6.81,6.81h0" transform="translate(-11.36 -10.18)" fill="#e54889"/>
        <rect id="Rectangle_12" data-name="Rectangle 12" x="57.35" y="104.34" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_34" data-name="Path 34" d="M102.8,106.61H75.53a6.81,6.81,0,0,1-6.81-6.81h0V95.15a6.81,6.81,0,0,1,6.81-6.81H102.8a6.81,6.81,0,0,1,6.81,6.81V99.8a6.81,6.81,0,0,1-6.81,6.81h0" transform="translate(-11.36 -10.18)" fill="#e54889"/>
        <rect id="Rectangle_13" data-name="Rectangle 13" x="57.35" y="78.16" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_35" data-name="Path 35" d="M102.8,80.62H75.53a6.81,6.81,0,0,1-6.81-6.81h0V69.16a6.81,6.81,0,0,1,6.81-6.81H102.8a6.81,6.81,0,0,1,6.81,6.81v4.65a6.81,6.81,0,0,1-6.81,6.81h0" transform="translate(-11.36 -10.18)" fill="#e54889"/>
        <rect id="Rectangle_14" data-name="Rectangle 14" x="57.35" y="52.17" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_36" data-name="Path 36" d="M158,203H130.78a6.81,6.81,0,0,1-6.82-6.81h0v-4.65a6.81,6.81,0,0,1,6.82-6.81H158a6.81,6.81,0,0,1,6.82,6.81v4.65A6.81,6.81,0,0,1,158,203h0" transform="translate(-11.36 -10.18)" fill="#813d8f"/>
        <rect id="Rectangle_15" data-name="Rectangle 15" x="112.6" y="174.59" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_37" data-name="Path 37" d="M158,180H130.78a6.81,6.81,0,0,1-6.82-6.81h0v-4.65a6.81,6.81,0,0,1,6.82-6.81H158a6.81,6.81,0,0,1,6.82,6.81h0v4.64A6.82,6.82,0,0,1,158,180h0" transform="translate(-11.36 -10.18)" fill="#813d8f"/>
        <rect id="Rectangle_16" data-name="Rectangle 16" x="112.6" y="151.54" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_38" data-name="Path 38" d="M158,156.93H130.78a6.81,6.81,0,0,1-6.82-6.81h0v-4.65a6.81,6.81,0,0,1,6.82-6.81H158a6.81,6.81,0,0,1,6.82,6.81v4.65a6.81,6.81,0,0,1-6.82,6.81h0" transform="translate(-11.36 -10.18)" fill="#813d8f"/>
        <rect id="Rectangle_17" data-name="Rectangle 17" x="112.6" y="128.48" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_39" data-name="Path 39" d="M158,132.6H130.78a6.81,6.81,0,0,1-6.82-6.81h0v-4.65a6.81,6.81,0,0,1,6.82-6.81H158a6.81,6.81,0,0,1,6.82,6.81h0v4.65A6.81,6.81,0,0,1,158,132.6h0" transform="translate(-11.36 -10.18)" fill="#813d8f"/>
        <rect id="Rectangle_18" data-name="Rectangle 18" x="112.6" y="104.15" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_40" data-name="Path 40" d="M158,106.43H130.78A6.82,6.82,0,0,1,124,99.61h0V95a6.81,6.81,0,0,1,6.82-6.81H158A6.81,6.81,0,0,1,164.86,95v4.64a6.82,6.82,0,0,1-6.82,6.82h0" transform="translate(-11.36 -10.18)" fill="#813d8f"/>
        <rect id="Rectangle_19" data-name="Rectangle 19" x="112.6" y="77.98" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_41" data-name="Path 41" d="M158,80.43H130.78A6.81,6.81,0,0,1,124,73.62h0V69a6.81,6.81,0,0,1,6.82-6.81H158A6.81,6.81,0,0,1,164.86,69h0v4.65A6.81,6.81,0,0,1,158,80.43h0" transform="translate(-11.36 -10.18)" fill="#813d8f"/>
        <rect id="Rectangle_20" data-name="Rectangle 20" x="112.6" y="51.98" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_42" data-name="Path 42" d="M158,54.44H130.78A6.81,6.81,0,0,1,124,47.63h0V43a6.81,6.81,0,0,1,6.82-6.81H158A6.81,6.81,0,0,1,164.86,43h0v4.65A6.81,6.81,0,0,1,158,54.44h0" transform="translate(-11.36 -10.18)" fill="#813d8f"/>
        <rect id="Rectangle_21" data-name="Rectangle 21" x="112.6" y="25.99" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_43" data-name="Path 43" d="M158,28.45H130.78A6.81,6.81,0,0,1,124,21.64h0V17a6.81,6.81,0,0,1,6.82-6.81H158A6.81,6.81,0,0,1,164.86,17h0v4.65A6.81,6.81,0,0,1,158,28.45h0" transform="translate(-11.36 -10.18)" fill="#813d8f"/>
        <rect id="Rectangle_22" data-name="Rectangle 22" x="112.6" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_44" data-name="Path 44" d="M215,203.23H187.76a6.81,6.81,0,0,1-6.81-6.81h0v-4.65a6.81,6.81,0,0,1,6.81-6.81H215a6.81,6.81,0,0,1,6.81,6.81v4.64a6.81,6.81,0,0,1-6.81,6.82h0" transform="translate(-11.36 -10.18)" fill="#503f92"/>
        <rect id="Rectangle_23" data-name="Rectangle 23" x="169.59" y="174.78" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_45" data-name="Path 45" d="M215,180.17H187.76a6.81,6.81,0,0,1-6.81-6.81h0v-4.65a6.81,6.81,0,0,1,6.81-6.81H215a6.81,6.81,0,0,1,6.81,6.81h0v4.65a6.81,6.81,0,0,1-6.81,6.81h0" transform="translate(-11.36 -10.18)" fill="#503f92"/>
        <rect id="Rectangle_24" data-name="Rectangle 24" x="169.59" y="151.72" width="40.89" height="18.27" rx="2.46" fill="none"/>
        <path id="Path_46" data-name="Path 46" d="M215,157.12H187.76A6.81,6.81,0,0,1,181,150.3h0v-4.64a6.81,6.81,0,0,1,6.81-6.81H215a6.81,6.81,0,0,1,6.81,6.81v4.64a6.81,6.81,0,0,1-6.81,6.82h0" transform="translate(-11.36 -10.18)" fill="#503f92"/>
        <rect id="Rectangle_25" data-name="Rectangle 25" x="169.59" y="128.67" width="40.89" height="18.27" rx="2.46" fill="none"/>
        </g>
      </g>
    </svg>
  )
}

export default AnimatedBeat