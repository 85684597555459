import React from "react"

// Social Media Logos
import facebookLogo from "../images/svgs/facebook.svg"
import instagramLogo from "../images/svgs/instagram.svg"
import linkedinLogo from "../images/svgs/linkedin.svg"
import youtubeLogo from "../images/svgs/youtube.svg"
import facebookLogoWhite from "../images/svgs/facebook-w.svg"
import instagramLogoWhite from "../images/svgs/instagram-w.svg"
import linkedinLogoWhite from "../images/svgs/linkedin-w.svg"
import youtubeLogoWhite from "../images/svgs/youtube-w.svg"

const Footer = () => {
  return (
    <div className="font-comfortaa xl:pt-8">
      <hr className="w-[50%] mx-auto xl:hidden" />
      <ul className="flex justify-center gap-2 my-4">
        <li className="hover:scale-110 ">
          <a href="https://www.linkedin.com/in/ehaimer" target="_blank">
            <img src={linkedinLogo} alt="LinkedIn Logo" />
          </a>
        </li>
        <li className="hover:scale-110 ">
          <a href="https://www.facebook.com/yasser.ehaimer" target="_blank">
            <img src={facebookLogo} alt="Facebook Logo" />
          </a>
        </li>
        <li className="hover:scale-110 ">
          <a href="https://instagram.com/ehaimer85" target="_blank">
            <img src={instagramLogo} alt="Instagram Logo" />
          </a>
        </li>
        <li className="hover:scale-110 ">
          <a href="https://www.youtube.com/@yasserEhaimer" target="_blank">
            <img src={youtubeLogo} alt="YouTube Logo" />
          </a>
        </li>
      </ul>
      <div className="flex justify-center sm:justify-between items-center px-8 py-6 bg-forth text-white font-bold ">
        <p>&copy; Yasir Elamin 2023</p>
        <ul className="sm:flex justify-center items-center gap-2 my-4 hidden">
          <li className="hover:scale-110 ">
            <a href="https://www.linkedin.com/in/ehaimer" target="_blank">
              <img
                src={linkedinLogoWhite}
                alt="LinkedIn Logo"
                className="w-8"
              />
            </a>
          </li>
          <li className="hover:scale-110 ">
            <a href="https://www.facebook.com/yasser.ehaimer" target="_blank">
              <img
                src={facebookLogoWhite}
                alt="Facebook Logo"
                className="w-8"
              />
            </a>
          </li>
          <li className="hover:scale-110 ">
            <a href="https://instagram.com/ehaimer85" target="_blank">
              <img
                src={instagramLogoWhite}
                alt="Instagram Logo"
                className="w-9"
              />
            </a>
          </li>
          <li className="hover:scale-110 ">
            <a href="https://www.youtube.com/@yasserEhaimer" target="_blank">
              <img src={youtubeLogoWhite} alt="YouTube Logo" className="w-9" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Footer
