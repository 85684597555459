import { Bars } from "react-loader-spinner"
import { useEffect } from "react"
import { motion } from "framer-motion"
import "../css/index.css"

const Spinner = ({ text }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [])

  return (
    <div className="fixed inset-x-0 bottom-0 top-[100px] bg-white flex justify-center z-50 font-comfortaa">
      <div className="z-10 flex flex-col gap-4 items-center mt-32">
        <Bars
          height="80"
          width="80"
          color="hsl(332, 100%, 51%)"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        <div className="text-spinner font-bold text-lg flex items-center gap-2 animate-bounce mt-4">
          {text}
          <div className="flex gap-1 mt-2">
            <motion.div
              initial={{ y: 0 }}
              animate={{ y: [0, -5, 0] }}
              transition={{ duration: 0.5, repeat: Infinity }}
              className="w-1 h-1 bg-spinner rounded-full "
            ></motion.div>
            <motion.div
              initial={{ y: 0 }}
              animate={{ y: [-5, -0, -5] }}
              transition={{ duration: 0.5, repeat: Infinity }}
              className="w-1 h-1 bg-spinner rounded-full "
            ></motion.div>
            <motion.div
              initial={{ y: 0 }}
              animate={{ y: [0, -5, 0] }}
              transition={{ duration: 0.5, repeat: Infinity }}
              className="w-1 h-1 bg-spinner rounded-full "
            ></motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Spinner
