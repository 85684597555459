import React, { useState, useEffect } from "react"

const ConfirmPasswordValidator = ({password, confirmPassword, setIsValid}) => {
  const [errors, setErrors] = useState({})
  const [confirmPasswordErrorKeys, setConfirmPasswordErrorKeys] = useState([])

  useEffect(() => {
    const passwordErrors = validate(password)
    setErrors(passwordErrors)
    setIsValid(Object.keys(passwordErrors).length === 0)
  }, [password, confirmPassword])

  function validate(password) {
    let validationErrors = {}
    let confirmPasswordErrorKeys = []
    if (password.trim() !== confirmPassword.trim()) {
      validationErrors.confirmPassword = "Passwords do not match"
      confirmPasswordErrorKeys.push("confirmPassword")
    }
    setConfirmPasswordErrorKeys(confirmPasswordErrorKeys)
    return validationErrors
  }

  return (
    <div>
      {confirmPasswordErrorKeys.map((errorKey) =>
        errors[errorKey] ? (
          <p key={errorKey} className="text-red-500">
            {errors[errorKey]}
          </p>
        ) : null
      )}
    </div>
  )
}

export default ConfirmPasswordValidator
