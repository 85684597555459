import React, { useState, useEffect } from "react"
import Spinner from "../components/Spinner"

const AdminContact = () => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    try {
      fetch(
        "https://3npfp281a6.execute-api.us-east-1.amazonaws.com/default/lambdagetforms-dev"
      )
        .then((res) => res.json())
        .then((data) => {
          setData(data.documents)
          setIsLoading(false)
        })
        .catch((error) => {
          console.error(error.message)
          setIsLoading(false)
        })
    } catch (error) {
      console.error(error.message)
      setIsLoading(false)
    }
  }, [])

  return (
    <>
      {isLoading ? (
        <Spinner text={"Loading Your Contact Requests"} />
      ) : (
        <div className="container flex flex-col grow font-comfortaa mb-4 overflow-x-hidden">
          <h1 className="text-2xl font-bold my-8 text-primary">
            Contact Requests
          </h1>
          <button
            type="button"
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-forth self-center text-white rounded font-bold w-[6.5rem] active:scale-95 active:bg-gray-200 mb-8 "
          >
            Refresh
          </button>
          <div className="space-y-4">
            {data.map((item) => (
              <div key={item._id} className="border p-4 rounded shadow">
                <p className="text-primary">
                  <strong className="text-forth">First Name:</strong>{" "}
                  {item.firstName}
                </p>
                <p className="text-primary">
                  <strong className="text-forth">Last Name:</strong>{" "}
                  {item.lastName}
                </p>
                <p className="text-primary">
                  <strong className="text-forth">Email:</strong> {item.email}
                </p>
                <p className="text-primary">
                  <strong className="text-forth">Phone Number:</strong>{" "}
                  {item.phoneNumber}
                </p>
                <p className="text-primary">
                  <strong className="text-forth">Message:</strong>{" "}
                  {item.message}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default AdminContact
