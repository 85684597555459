import React, { useState, useEffect } from "react"

function UsernameValidator({ fieldType, content, setIsValid }) {
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const usernameErrors = validate(content)
    setErrors(usernameErrors)
    setIsValid(Object.keys(usernameErrors).length === 0)
  }, [content])

  function validate(content) {
    let validationErrors = {}
    if (content.trim() === "") {
      validationErrors.usernameRequired = `${fieldType} is required`
    }
    return validationErrors
  }

  return (
    <div>
      {errors.usernameRequired ? (
        <p className="text-red-500">{errors.usernameRequired}</p>
      ) : null}
    </div>
  )
}

export default UsernameValidator

