import { useState } from "react"
import { Auth } from "aws-amplify"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../context/AuthContext"
import SignInImage from "../images/svgs/signIn.svg"
import { motion } from "framer-motion"
import EmptyFieldValidator from "../components/form Validators/EmptyFieldValidator"
import { EyeIcon } from "@heroicons/react/24/solid"
import { EyeSlashIcon } from "@heroicons/react/24/solid"
import Spinner from "../components/Spinner"

const SignIn = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isValidUsername, setIsValidUsername] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const [notification, setNotification] = useState("")
  const navigate = useNavigate()
  const { setIsAuthenticated, setUser } = useAuthContext()
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  })

  function handleChange(ev) {
    const { name, value } = ev.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  async function handleSubmit(ev) {
    ev.preventDefault()
    setIsSubmitted(true)
    if (
      isValidUsername ||
      isValidPassword ||
      (formData.username !== "" && formData.password !== "")
    ) {
      try {
        setIsLoading(true)
        setUser(await Auth.signIn(formData.username, formData.password))
        setIsAuthenticated(true)
        setIsSubmitted(false)
        setIsLoading(false)
        navigate("/") // This should take you to the current page
      } catch (error) {
        setNotification(error.message)
        setIsLoading(false)
      }
    }
  }

  function handleSignUp() {
    navigate("/signup")
  }

  return (
    <>
      {isLoading ? (
        <Spinner text={"Signing In"} />
      ) : (
        <div className="container flex flex-col items-center grow font-comfortaa mb-4 overflow-x-hidden">
          <h1 className="text-2xl lg:text-3xl mt-8 mb-4 text-primary font-bold self-start">
            Sign In
          </h1>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 3 }}
          >
            <motion.div
              initial={{ scale: 1, rotate: 0 }}
              animate={{ scale: 1.2, rotate: [5, -5, 5, -5, 0] }}
              transition={{ delay: 0.2 }}
              className="mb-16"
            >
              <img src={SignInImage} alt="lock image" />
            </motion.div>
            <form
              onSubmit={handleSubmit}
              className="w-full lg:w-[850px] px-6 py-8 rounded-lg shadow-lg self-center mb-16"
            >
              <div className="flex flex-col gap-6">
                <div>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    placeholder="Username"
                    onChange={handleChange}
                    autoComplete="username"
                    className="w-full py-1 px-2 border-b text-forth focus:outline-forth"
                  />
                  {isSubmitted && (
                    <EmptyFieldValidator
                      fieldType={"Username"}
                      content={formData.username}
                      setIsValid={setIsValidUsername}
                    />
                  )}
                </div>
                <div>
                  <div className="relative">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      id="password"
                      name="password"
                      value={formData.password}
                      placeholder="Password"
                      onChange={handleChange}
                      autoComplete="current-password"
                      className="w-full py-1 px-2 border-b text-forth focus:outline-forth"
                    />
                    <button
                      type="button"
                      onClick={() => setPasswordVisible(!isPasswordVisible)}
                      className="absolute right-4 top-1/2 -translate-y-1/2 "
                    >
                      {isPasswordVisible ? (
                        <EyeSlashIcon className="w-4 text-forth" />
                      ) : (
                        <EyeIcon className="w-4 text-forth" />
                      )}
                    </button>
                  </div>
                  {isSubmitted && (
                    <EmptyFieldValidator
                      fieldType={"Password"}
                      content={formData.password}
                      setIsValid={setIsValidPassword}
                    />
                  )}
                </div>
                {notification && <p className="text-red-500">{notification}</p>}
                <button
                  onClick={() => navigate("/forgotPassword")}
                  className=" text-forth active:scale-95 active:text-gray-200"
                  type="button"
                >
                  Forgot Password ?
                </button>
                <div className="flex gap-12 mt-4 justify-center">
                  <button
                    type="submit"
                    className="px-4 py-2 bg-primary text-white rounded font-bold w-[6.5rem] active:scale-95 active:bg-gray-200 hover:scale-105"
                  >
                    Sign In
                  </button>
                  <button
                    type="button"
                    onClick={handleSignUp}
                    className="px-4 py-2 bg-forth text-white rounded font-bold w-[6.5rem] active:scale-95 active:bg-gray-200 hover:scale-105"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      )}
    </>
  )
}

export default SignIn
