// Components
import AnimatedBeat from "../components/AnimatedBeat"

// Images
import beatApp from "../images/apps/beat-app.jpg"
import qatarApp from "../images/apps/qatar-app.jpg"
import form from "../images/apps/interactive-form.jpg"
import webSite from "../images/apps/responsive-website.jpg"
import todo from "../images/apps/todo.jpg"
import vanillaTodo from "../images/apps/vanillaTodo.png"
import currencyConverter from "../images/apps/currency-converter.jpg"
import ticTacToeImage from "../images/apps/TicTacToeApp.png"
import drumKitImage from "../images/apps/drumKit.png"
import githubLogo from "../images/svgs/github.svg"
import { FilmIcon } from "@heroicons/react/24/solid"
import { NavLink } from "react-router-dom"
import MotionSection from "../components/MotionSection"

//Icons
import { motion } from "framer-motion"

const Portfolio = () => {
  const viewportWidth = typeof window !== "undefined" ? window.innerWidth : 0

  return (
    <div className="font-comfortaa overflow-x-hidden overflow-y-hidden">
      <div className="shadow-md bg-white absolute inset-0 overflow-y-hidden overflow-x-hidden mt-[100px] grid grid-rows-2">
        <div className="p-8">
          <div className="h-[70%] flex justify-center">
            <AnimatedBeat />
          </div>
          <img
            src={require("../images/apps/beat-logo.png")}
            alt="Yasir Elamin portfolio picture"
            className="h-[30%] mx-auto pt-4 mt-4"
          />
        </div>
        <div className="relative">
          <div className="absolute inset-0 h-full">
            <p className="text-primary font-bold text-3xl lg:text-5xl animate-pulse absolute top-[2rem] left-[1.5rem]">
              Interface Design{" "}
            </p>
            <p className="text-forth font-bold text-3xl lg:text-5xl  whitespace-nowrap animate-bounce absolute top-[35%] right-[11%] ">
              Responsive Websites
            </p>
            <p className="text-[#E3276C] font-bold text-3xl lg:text-5xl  whitespace-nowrap animate-pulse absolute top-[55%] left-[13%]">
              Mobile Applications
            </p>
            <p className="text-[#7C70D2] font-bold text-3xl lg:text-5xl  whitespace-nowrap animate-slideFromBottom absolute bottom-[2rem] left-[1.5rem]">
              Project Management
            </p>
          </div>
          <div className="absolute inset-0 -z-10 h-full">
            <motion.div
              className=" h-[15%] w-[75%] bg-pink-50 rounded-lg -z-10 shadow-sm absolute top-[18%]"
              initial={{ x: 100 }}
              animate={{ x: [0, 20, 0, 20, 0, 10, 0, 10, 0] }}
              transition={{ delay: 1 }}
            ></motion.div>
            <motion.div
              className="  h-[15%] w-[75%] bg-violet-50 rounded-lg -z-10 shadow-sm absolute top-[45%] right-[0%] "
              initial={{ x: 100 }}
              animate={{ x: [0, 20, 0, 20, 0, 10, 0, 10, 0] }}
              transition={{ delay: 2 }}
            ></motion.div>
            <motion.div
              className=" h-[15%] w-[75%] bg-blue-50  rounded-lg -z-10 shadow-sm absolute bottom-[10%]"
              initial={{ y: 100 }}
              animate={{ y: [0, 20, 0, 20, 0, 10, 0, 10, 0] }}
              transition={{ delay: 3 }}
            ></motion.div>
          </div>
        </div>
      </div>
      <div className=" mt-[100vh] flex flex-col gap-12 container">
        <div className="flex flex-col lg:grid grid-cols-2 gap-8 items-center">
          <MotionSection
            initialProps={{ x: -viewportWidth }}
            animateProps={{ x: 0 }}
            transition={{
              duration: 0.3,
              stiffness: 120,
              type: "spring",
              delay: 0.5,
            }}
          >
            <img src={beatApp} alt=" APP ScreenShot" className="rounded-lg " />
          </MotionSection>
          <motion.div
            className="flex flex-col gap-4 pt-4"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 2 }}
          >
            <h3 className="text-primary text-3xl xl:text-4xl font-bold mt-4 lg:mt-0">
              Beat
            </h3>
            <p className="text-forth text-lg xl:text-lg lg:pb-4">
              I am delighted to present Beat, a music app prototype that
              underscores my proficiency in interface design. This endeavor
              encompasses a meticulously crafted landing page, a thoughtfully
              designed logo, and fundamental functionality. Developed using
              Adobe XD, this prototype showcases my ability to harmoniously meld
              aesthetics with user experience, creating an intuitive and
              engaging environment. This project exemplifies my commitment to
              delivering visually appealing and user-centric design solutions.
            </p>
          </motion.div>
        </div>
        <div className="flex flex-col lg:grid grid-cols-2 gap-8 items-center">
          <MotionSection
            initialProps={{ x: viewportWidth }}
            animateProps={{ x: 0 }}
            transition={{
              duration: 0.3,
              stiffness: 120,
              type: "spring",
              delay: 0.5,
            }}
            className="lg:mt-24"
          >
            <img src={qatarApp} alt=" APP ScreenShot" className="rounded-lg" />
          </MotionSection>
          <motion.div
            className="flex flex-col gap-4 pt-4 lg:order-first"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 2 }}
          >
            <h3 className="text-primary text-3xl xl:text-4xl font-bold mt-4 lg:text-right">
              APP Icons
            </h3>
            <p className="text-forth text-lg xl:text-lg lg:text-right">
              I take pride in having presented an app icon meticulously designed
              for the Qatar World Cup 2022, using Adobe Illustrator as my tool
              of choice. This creation stands as a testament to my adeptness in
              the realm of icon design, highlighting my proficiency in
              translating intricate themes into succinct visual representations.
              By leveraging Adobe Illustrator's precision and my comprehensive
              grasp of design principles, I crafted an icon that encapsulated
              the essence of the event while upholding professional standards.
              This project underscored my commitment to delivering icons that
              seamlessly merged creativity with purposeful design, thereby
              affirming my capabilities in icon designing.
            </p>
          </motion.div>
        </div>
        <div className="flex flex-col lg:grid grid-cols-2 gap-8 items-center">
          <MotionSection
            initialProps={{ x: -viewportWidth }}
            animateProps={{ x: 0 }}
            transition={{
              duration: 0.3,
              stiffness: 120,
              type: "spring",
              delay: 0.5,
            }}
            className="lg:mt-8"
          >
            <img src={webSite} alt=" APP ScreenShot" className="rounded-lg" />
          </MotionSection>
          <motion.div
            className="flex flex-col gap-4 pt-4"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 2 }}
          >
            <h3 className="text-primary text-3xl xl:text-4xl font-bold mt-4">
              Responsive Website
            </h3>
            <p className="text-forth text-lg xl:text-lg">
              In the pursuit of elevating user experiences, I am dedicated to
              crafting responsive web applications that transcend device
              boundaries. By employing a unified codebase, I ensure seamless
              adaptability across diverse screen sizes, including mobiles,
              tablets, laptops, and large displays. This commitment to
              responsive design guarantees that users encounter a consistently
              optimal interaction, regardless of the device they choose. My
              proficiency in creating responsive web applications reflects my
              dedication to providing enhanced user accessibility and engagement
              through unified, cross-platform experiences.
            </p>
          </motion.div>
        </div>
        <div className="flex flex-col lg:grid grid-cols-2 gap-8 items-center">
          <MotionSection
            initialProps={{ x: viewportWidth }}
            animateProps={{ x: 0 }}
            transition={{
              duration: 0.3,
              stiffness: 120,
              type: "spring",
              delay: 0.5,
            }}
            className="lg:mt-8"
          >
            <img src={form} alt=" APP ScreenShot" className="rounded-lg" />
          </MotionSection>
          <motion.div
            className="flex flex-col gap-4 pt-4 lg:order-first"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 2 }}
          >
            <h3 className="text-primary text-3xl xl:text-4xl font-bold mt-4 lg:text-right lg:whitespace-nowrap">
              Dynamic Web Components
            </h3>
            <p className="text-forth text-lg xl:text-lg lg:text-right">
              In addition to my expertise in web development, I am also adept at
              crafting dynamic web components, like interactive forms, that
              cater to a diverse range of customer requirements. These
              meticulously designed components are tailored to accommodate
              various types of user interactions and preferences, effectively
              enhancing user engagement and satisfaction. My commitment to
              delivering solutions that align with client needs is exemplified
              through these versatile and customer-centric web components.
            </p>
          </motion.div>
        </div>
      </div>
      <div className="bg-forth">
        <motion.h2
          className="text-white font-bold text-3xl lg:text-5xl  text-center py-8 lg:py-24 lg:text-center"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.8 }}
          viewport={{ once: true }}
        >
          Projects
        </motion.h2>
      </div>
      <div className="bg-primary">
        <motion.h2
          className="text-white font-bold text-2xl lg:text-4xl lg:mb-8 text-center py-2 lg:py-8 lg:text-center mb-8"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.8 }}
          viewport={{ once: true }}
        >
          Vanilla JavaScript Apps
        </motion.h2>
      </div>
      {/* Vanilla JavaScript apps */}
      <section className="mb-12 flex flex-col gap-12 container">
        {/* The Drum Kit App */}
        <div className="flex flex-col lg:grid grid-cols-2 gap-8 items-center">
          <MotionSection
            initialProps={{ x: -viewportWidth }}
            animateProps={{ x: 0 }}
            transition={{
              duration: 0.3,
              stiffness: 120,
              type: "spring",
              delay: 0.5,
            }}
            className="lg:mt-8 border shadow-md rounded-md w-[320px]"
          >
            <img
              src={drumKitImage}
              alt=" APP ScreenShot"
              className="rounded-lg "
            />
          </MotionSection>
          <div className="lg:mt-16">
            <motion.div
              className="flex flex-col gap-4 pt-4"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 2 }}
            >
              <h3 className="text-primary text-2xl xl:text-3xl font-bold mt-4">
                The Drum Kit App
              </h3>
              <p className="text-forth text-lg xl:text-lg">
                This simple web app allows you to unleash your inner drummer
                with just a click, tap, or keyboard key press. Experience the
                joy of drumming life with a variety of drum sounds. Built using
                pure vanilla JavaScript, this app offers a fun and interactive
                way to create rhythmic beats. Give it a try and start drumming
                to your own rhythm!
              </p>
            </motion.div>
            <div className="mb-4 flex flex-col gap-2 text-forth font-bold self-start mt-8 text-[15px]">
              <div className="flex gap-2 items-center hover:scale-105 ">
                <FilmIcon className="w-8" />
                <a href="/projects/drum-kit/index.html">
                  Explore the interactive application.
                </a>
              </div>
              <div className="flex gap-2 items-center hover:scale-105 ">
                <img src={githubLogo} alt="gitHub Logo" className="w-8" />
                <a href="https://github.com/ehaimer/drum-kit">
                  Display the source code on GitHub.
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {/* The Tic Tac Toe App RL */}
        <div className="flex flex-col lg:grid grid-cols-2 gap-8 items-center">
          <MotionSection
            initialProps={{ x: viewportWidth }}
            animateProps={{ x: 0 }}
            transition={{
              duration: 0.3,
              stiffness: 120,
              type: "spring",
              delay: 0.5,
            }}
            className="lg:mt-8 border shadow-md rounded-md w-[300px] lg:mx-auto"
          >
            <img
              src={ticTacToeImage}
              alt=" APP ScreenShot"
              className="rounded-lg"
            />
          </MotionSection>
          <motion.div
            className="flex flex-col gap-4 pt-4 lg:order-first "
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 2 }}
          >
            <h3 className="text-primary text-2xl xl:text-3xl font-bold mt-4">
              The Tic Tac Toe App
            </h3>
            <p className="text-forth text-lg xl:text-lg">
              This is a simple implementation of the classic Tic-Tac-Toe game
              using JavaScript, HTML, and CSS. This project serves as a great
              introduction to web development and JavaScript.
            </p>
            <div className="mb-4 flex flex-col gap-2 text-forth font-bold self-start mt-8 text-[15px]">
              <div className="flex gap-2 items-center hover:scale-105 ">
                <FilmIcon className="w-8" />
                <a href="/projects/tic-tac-toe-app/index.html">
                  Explore the interactive application.
                </a>
              </div>
              <div className="flex gap-2 items-center hover:scale-105 ">
                <img src={githubLogo} alt="gitHub Logo" className="w-8" />
                <a href="https://github.com/ehaimer/tic-tac-toe">
                  Display the source code on GitHub.
                </a>
              </div>
            </div>
          </motion.div>
        </div>
        <hr />
        {/* Vanilla Todo App LR */}
        <div className="flex flex-col lg:grid grid-cols-2 gap-8 items-center">
          <MotionSection
            initialProps={{ x: -viewportWidth }}
            animateProps={{ x: 0 }}
            transition={{
              duration: 0.3,
              stiffness: 120,
              type: "spring",
              delay: 0.5,
            }}
            className="lg:mt-8 border shadow-md rounded-md w-[320px]"
          >
            <img
              src={vanillaTodo}
              alt=" APP ScreenShot"
              className="rounded-lg "
            />
          </MotionSection>
          <div className="lg:mt-16">
            <motion.div
              className="flex flex-col gap-4 pt-4"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 2 }}
            >
              <h3 className="text-primary text-2xl xl:text-3xl font-bold mt-4">
                Simple Vanilla JS Todo List
              </h3>
              <p className="text-forth text-lg xl:text-lg">
                This Simple To-Do List App is a minimalistic task management
                application built using Vanilla Javascript, designed to help you
                keep track of your daily tasks and activities. It provides a
                straightforward and intuitive interface, making it easy for
                users to add, and remove tasks as needed.
              </p>
            </motion.div>
            <div className="mb-4 flex flex-col gap-2 text-forth font-bold self-start mt-8 text-[15px]">
              <div className="flex gap-2 items-center hover:scale-105 ">
                <FilmIcon className="w-8" />
                <a href="/projects/vanilla-todo-list/index.html">
                  Explore the interactive application.
                </a>
              </div>
              <div className="flex gap-2 items-center hover:scale-105 ">
                <img src={githubLogo} alt="gitHub Logo" className="w-8" />
                <a href="https://github.com/ehaimer/vanilla-todo-list">
                  Display the source code on GitHub.
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-primary">
        <motion.h2
          className="text-white font-bold text-2xl lg:text-4xl lg:mb-8 text-center py-2 lg:py-8 lg:text-center mb-8"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.8 }}
          viewport={{ once: true }}
        >
          React Apps
        </motion.h2>
      </div>
      {/* React Apps */}
      <section className="mb-12 flex flex-col gap-12 container">
        {/* The Simple Todo list App LR */}
        <div className="flex flex-col lg:grid grid-cols-2 gap-8 items-center">
          <MotionSection
            initialProps={{ x: -viewportWidth }}
            animateProps={{ x: 0 }}
            transition={{
              duration: 0.3,
              stiffness: 120,
              type: "spring",
              delay: 0.5,
            }}
            className="lg:mt-8 border shadow-md rounded-md"
          >
            <img src={todo} alt=" APP ScreenShot" className="rounded-lg" />
          </MotionSection>
          <div className="lg:mt-16">
            <motion.div
              className="flex flex-col gap-4 pt-4"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 2 }}
            >
              <h3 className="text-primary text-2xl xl:text-3xl font-bold mt-4">
                Simple React Todo List
              </h3>
              <p className="text-forth text-lg xl:text-lg">
                This Simple To-Do List React App is a minimalistic task
                management application built using React, designed to help you
                keep track of your daily tasks and activities. It provides a
                straightforward and intuitive interface, making it easy for
                users to add, edit, and remove tasks as needed.
              </p>
            </motion.div>
            <div className="mb-4 flex flex-col gap-2 text-forth font-bold  self-start mt-8 text-[15px]">
              <NavLink
                to={`/projects/todo-list`}
                className="flex gap-2 items-center hover:scale-105 "
              >
                <FilmIcon className="w-8" />
                Explore the interactive application.
              </NavLink>
              <div className="flex gap-2 items-center hover:scale-105 ">
                <img src={githubLogo} alt="gitHub Logo" className="w-8" />
                <a href="https://github.com/ehaimer/todo-list">
                  Display the source code on GitHub.
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {/* The Currency Converter App RL */}
        <div className="flex flex-col lg:grid grid-cols-2 gap-8 items-center">
          <MotionSection
            initialProps={{ x: viewportWidth }}
            animateProps={{ x: 0 }}
            transition={{
              duration: 0.3,
              stiffness: 120,
              type: "spring",
              delay: 0.5,
            }}
            className="lg:mt-8 border shadow-md rounded-md w-[300px] lg:mx-auto"
          >
            <img
              src={currencyConverter}
              alt=" APP ScreenShot"
              className="rounded-lg"
            />
          </MotionSection>
          <motion.div
            className="flex flex-col gap-4 pt-4 lg:order-first "
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 2 }}
          >
            <h3 className="text-primary text-2xl xl:text-3xl font-bold mt-4">
              The Currency Converter
            </h3>
            <p className="text-forth text-lg xl:text-lg">
              The Currency Converter App is a simple and user-friendly web
              application built using React. It allows users to easily convert
              between different currencies in real-time. Whether you're a
              traveler, business professional, or anyone dealing with
              international currencies, this app provides a convenient solution
              for all your currency conversion needs.
            </p>
            <div className="mb-4 flex flex-col gap-2 text-forth font-bold  self-start mt-8 text-[15px]">
              <NavLink
                to={`/projects/currency-converter`}
                className="flex gap-2 items-center hover:scale-105 "
              >
                <FilmIcon className="w-8" />
                Explore the interactive application.
              </NavLink>
              <div className="flex gap-2 items-center hover:scale-105">
                <img src={githubLogo} alt="gitHub Logo" className="w-8" />
                <a href="https://github.com/ehaimer/currency-converter">
                  Display the source code on GitHub.
                </a>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  )
}

export default Portfolio
