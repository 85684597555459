import { useState } from "react"
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline"
import { Auth } from "aws-amplify"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import SignInImage from "../images/svgs/signIn.svg"
import { useAuthContext } from "../context/AuthContext"
import Spinner from "../components/Spinner"

const ForgetPassword = () => {
  const [forgetPasswordUsername, setForgetPasswordUsername] = useState("")
  const [notification, setNotification] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { setCodeUser } = useAuthContext()

  async function handleSubmit(ev) {
    ev.preventDefault()
    try {
      setIsLoading(true)
      await Auth.forgotPassword(forgetPasswordUsername)
      setCodeUser(forgetPasswordUsername)
      setIsLoading(false)
      navigate("/sendCode")
      setNotification("")
    } catch (error) {
      setNotification(error.message)
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner text={"Sending Code"} />
      ) : (
        <div className="container flex flex-col items-center grow font-comfortaa mb-4 overflow-x-hidden">
          <h1 className="text-2xl lg:text-3xl mt-8 mb-4 text-primary font-bold self-start">Sign In</h1>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 3 }}
          >
            <motion.div
              initial={{ scale: 1, rotate: 0 }}
              animate={{ scale: 1.2, rotate: [5, -5, 5, -5, 0] }}
              transition={{ delay: 0.2 }}
            >
              <img src={SignInImage} alt="lock image" />
            </motion.div>
            <form
              onSubmit={handleSubmit}
              className="w-full lg:w-[850px] px-6 py-8 rounded-lg shadow-lg self-center"
            >
              <div className="flex flex-col gap-4">
                <div className="flex gap-4">
                  <input
                    type="text"
                    id="forgetPassword"
                    name="forgetPassword"
                    value={forgetPasswordUsername}
                    placeholder="Enter Username"
                    onChange={(ev) =>
                      setForgetPasswordUsername(ev.target.value)
                    }
                    autoComplete="username"
                    className="w-full py-1 px-2 border-b text-forth focus:outline-forth"
                  />
                  <button
                    type="submit"
                    className="px-4 whitespace-nowrap py-2 bg-primary text-white  rounded font-bold  active:scale-95 active:bg-gray-200"
                  >
                    Send Code
                  </button>
                </div>
                {notification && <p className="text-red-500">{notification}</p>}
                <button
                  className="flex items-center text-forth mt-2 gap-2"
                  onClick={() => navigate("/signin")}
                >
                  <ArrowSmallLeftIcon className="w-4" />
                  Back
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      )}
    </>
  )
}

export default ForgetPassword
