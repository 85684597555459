import { useEffect } from "react"
import { motion } from "framer-motion"

function Modal({ text, isOpen, onClose, button }) {
  useEffect(() => {
    // If the modal is open, prevent scrolling on the body
    if (isOpen) {
      document.body.style.overflow = "hidden"
    }

    // Cleanup function to revert the style back when the modal is closed or if the component is unmounted
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [isOpen]) // This effect will only re-run if the 'isOpen' prop changes

  useEffect(() => {
    let timer
    if (!button) {
      timer = setTimeout(onClose, 2000)
    }
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [button, onClose])

  if (!isOpen) {
    return null
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <motion.div
        initial={{ scale: 0.1, opacity: 0 }}
        animate={{ scale: [0.1, 1.5, 1], opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="relative bg-white rounded-lg p-8 m-8 flex flex-col items-center gap-4"
      >
        <p className="text-forth text-center">{text}</p>
        {button && (
          <button
            className="px-4 py-2 bg-primary text-white rounded font-bold w-[6.5rem] active:scale-95 active:bg-gray-200"
            onClick={onClose}
          >
            {button}
          </button>
        )}
      </motion.div>
    </div>
  )
}

export default Modal

// import Modal from "../components/Modal"
// import { useState } from "react"
// const [isModalOpen, setModalOpen] = useState(false)
//       <Modal
//         text="Insert Some Text"
//         isOpen={isModalOpen}
//         onClose={() => setModalOpen(false)}
//         button={"Close"}
//       />
