import { BookOpenIcon } from "@heroicons/react/24/outline"
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline"
import { NavLink } from "react-router-dom"
import { useState, useEffect } from "react"
import Spinner from "../components/Spinner"

const BlogList = () => {
  const [blogs, setBlogs] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetch(
      "https://0g4pkboib3.execute-api.us-east-1.amazonaws.com/default/lambdagetblogs-dev"
    )
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data.documents)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error.message)
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      {isLoading ? (
        <Spinner text={"Loading Blogs List"} />
      ) : (
        <div className="my-8 container font-comfortaa overflow-x-hidden">
          <h1 className="font-bold text-2xl lg:text-4xl mb-4 text-primary lg:my-8">
            Welcome to my blogs!
          </h1>
          <p className="mb-6 text-forth lg:text-xl">
            I am thrilled to have you here, joining me on this exciting journey
            of exploration and discovery. This digital space is where I will be
            sharing my thoughts, insights, and experiences on a wide range of
            topics that captivate my curiosity. From the realms of technology,
            science, and art to the wonders of nature, personal growth, and
            beyond, I aspire to create a platform that sparks inspiration,
            ignites meaningful conversations, and fosters a sense of connection.
            So, grab a cup of your favorite beverage, get comfortable, and let's
            embark on this enlightening adventure together. Welcome once again,
            and I can't wait to dive into the captivating world of ideas and
            knowledge with you!
          </p>
          {blogs.map((blog) => (
            <div key={blog._id}>
              <p className="font-bold text-xl mb-4 text-primary lg:text-3xl lg:my-8">
                {blog.title}
              </p>
              <img
                src={blog.cover}
                alt="Blog Cover"
                className="mb-4 rounded lg:rounded-lg shadow-xl"
              />
              <div className="flex justify-center gap-4 text-forth font-bold lg:text-lg">
                <p>{new Date(blog.date).toDateString()}</p>
                <div className="flex gap-2">
                  <p className="mr-2">-</p>
                  <BookOpenIcon className="w-4" />
                  {blog.readingTime}
                </div>
              </div>
              <div className="my-4 text-forth lg:text-xl">
                {blog.body[0].paragraphs[0].substring(0, 150) + "..."}
                <br />
              </div>
              <div className="mb-4 flex gap-2 text-forth font-bold text-lg">
                <NavLink
                  to={`/blogs/${blog._id}`}
                  className="flex gap-2 hover:scale-105"
                >
                  Read the Blog
                  <ArrowSmallRightIcon className="w-4" />
                </NavLink>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default BlogList
