import { useState } from "react"
import PasswordValidator from "../components/form Validators/PasswordValidator"
import { Auth } from "aws-amplify"
import { useAuthContext } from "../context/AuthContext"
import { useNavigate } from "react-router-dom"
import EmptyFieldValidator from "../components/form Validators/EmptyFieldValidator"
import { EyeIcon } from "@heroicons/react/24/solid"
import { EyeSlashIcon } from "@heroicons/react/24/solid"
import SignInImage from "../images/svgs/signIn.svg"
import { motion } from "framer-motion"
import Spinner from "../components/Spinner"

const SendCode = () => {
  const [isCodePasswordVisible, setCodePasswordVisible] = useState(false)
  const [newPassword, setNewPassword] = useState("")
  const [code, setCode] = useState("")
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [notification, setNotification] = useState("")
  const { setIsAuthenticated } = useAuthContext()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const navigate = useNavigate()
  const { codeUser } = useAuthContext()

  async function handleSubmit(ev) {
    ev.preventDefault()
    setIsSubmitted(true)
    try {
      setIsLoading(true)
      await Auth.forgotPasswordSubmit(codeUser, code, newPassword)
      setNotification("")
      setIsAuthenticated(true)
      setIsLoading(false)
      navigate("/") // This should take you to the current page
    } catch (error) {
      setNotification(error.message)
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner text={"Signing In"} />
      ) : (
        <div className="container flex flex-col items-center grow font-comfortaa mb-4 overflow-x-hidden">
          <h1 className="text-2xl lg:text-3xl mt-8 mb-4 text-primary font-bold self-start">Sign In</h1>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 3 }}
          >
            <motion.div
              initial={{ scale: 1, rotate: 0 }}
              animate={{ scale: 1.2, rotate: [5, -5, 5, -5, 0] }}
              transition={{ delay: 0.2 }}
            >
              <img src={SignInImage} alt="lock image" />
            </motion.div>
            <form
              onSubmit={handleSubmit}
              className="w-full lg:w-[850px] px-6 py-8 rounded-lg shadow-lg self-center"
            >
              <div className="flex flex-col gap-4">
                <p className="text-primary">
                  Please enter the verification code sent to your email and a
                  new password.
                </p>
                <div>
                  <input
                    type="code"
                    id="code"
                    name="code"
                    value={code}
                    placeholder="Enter Code"
                    onChange={(ev) => setCode(ev.target.value)}
                    autoComplete="text"
                    className="w-full py-1 px-2 border-b text-forth focus:outline-forth"
                  />
                  {isSubmitted && (
                    <EmptyFieldValidator
                      fieldType={"Code"}
                      content={code}
                      setIsValid={setIsValidPassword}
                    />
                  )}
                </div>
                <div className="relative">
                  <input
                    type={isCodePasswordVisible ? "text" : "password"}
                    id="newPassword"
                    name="newPassword"
                    value={newPassword}
                    placeholder="New Password"
                    onChange={(ev) => setNewPassword(ev.target.value)}
                    autoComplete="password"
                    className="w-full py-1 px-2 border-b text-forth focus:outline-forth"
                  />
                  <button
                    type="button"
                    onClick={() =>
                      setCodePasswordVisible(!isCodePasswordVisible)
                    }
                    className="absolute right-4 top-1/2 -translate-y-1/2 "
                  >
                    {isCodePasswordVisible ? (
                      <EyeSlashIcon className="w-4 text-forth" />
                    ) : (
                      <EyeIcon className="w-4 text-forth" />
                    )}
                  </button>
                </div>
                {notification && <p className="text-red-500">{notification}</p>}
                {isSubmitted && (
                  <PasswordValidator
                    password={newPassword}
                    setIsValid={setIsValidPassword}
                  />
                )}
                <button
                  type="submit"
                  className="px-4 py-2 bg-primary text-white rounded font-bold w-[6.5rem] active:scale-95 active:bg-gray-200 "
                >
                  Submit
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      )}
    </>
  )
}

export default SendCode
