import { createContext, useState, useContext, useEffect } from "react"

// Create the AuthContext
const AuthContext = createContext()

// Create the AuthContextProvider component
export function AuthContextProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState(null)
  const [codeUser, setCodeUser] = useState(null)

  useEffect(() => {
    // On mount, try to get the user data from local storage
    const storedUser = localStorage.getItem("user")
    if (storedUser) {
      setUser(JSON.parse(storedUser))
      setIsAuthenticated(true)
      // localStorage.setItem("isWelcomed", JSON.stringify(true))
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated && user) {
      localStorage.setItem("user", JSON.stringify(user))
      setTimeout(() => {
        localStorage.setItem("isWelcomed", JSON.stringify(true))
      }, 3000);
    } else {
      localStorage.removeItem("user")
    }
  }, [isAuthenticated, user])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        codeUser,
        setCodeUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

// Custom hook to access the AuthContext anywhere in your application
export function useAuthContext() {
  return useContext(AuthContext)
}
