import React from "react"
import ReactDOM from "react-dom/client"
import "./css/index.css"
import { RouterProvider } from "react-router-dom"
import { router } from "./router"
import { Amplify } from "aws-amplify"
import awsconfig from "./aws-exports"
import "@aws-amplify/ui-react/styles.css"
import { AuthContextProvider } from "./context/AuthContext"

Amplify.configure(awsconfig)

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <RouterProvider router={router} />
    </AuthContextProvider>
  </React.StrictMode>
)
