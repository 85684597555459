import React, { useState, useEffect } from "react"

const PasswordValidator = ({password, setIsValid}) => {
  const [errors, setErrors] = useState({})
  const [passwordErrorKeys, setPasswordErrorKeys] = useState([])

  useEffect(() => {
    const passwordErrors = validate(password)
    setErrors(passwordErrors)
    setIsValid(Object.keys(passwordErrors).length === 0)
  }, [password])

  function validate(password) {
    let validationErrors = {}
    let passwordErrorKeys = []
    if (password.trim() === "") {
      // Check if Password Exist
      validationErrors.passwordExist = "Password is required"
      passwordErrorKeys.push("passwordExist")
    }
    if (password.length < 8) {
      // Check for minimum length
      validationErrors.passwordLength = "Password must be at least 8 characters long."
      passwordErrorKeys.push("passwordLength")
    }
    if (!/(?=.*\d)/.test(password)) {
      // Check for a digit
      validationErrors.passwordHasDigit = "Password must contain at least one digit."
      passwordErrorKeys.push("passwordHasDigit")
    }
    if (!/(?=.*[a-z])/.test(password)) {
      // Check for a lowercase letter
      validationErrors.passwordHasLowercase =
        "Password must contain at least one lowercase letter."
      passwordErrorKeys.push("passwordHasLowercase")
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      // Check for an uppercase letter
      validationErrors.passwordHasUppercase =
        "Password must contain at least one uppercase letter."
      passwordErrorKeys.push("passwordHasUppercase")
    }
    if (!/(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/.test(password)) {
      // Check for a special character
      validationErrors.passwordHasSpecialChar =
        "Password must contain at least one special character."
      passwordErrorKeys.push("passwordHasSpecialChar")
    }
    setPasswordErrorKeys(passwordErrorKeys)
    return validationErrors
  }

  return (
    <div>
      {passwordErrorKeys.map((errorKey) =>
        errors[errorKey] ? (
          <p key={errorKey} className="text-red-500">
            {errors[errorKey]}
          </p>
        ) : null
      )}
    </div>
  )
}

export default PasswordValidator
