import React, { useState } from "react"
import { Bars3Icon } from "@heroicons/react/24/solid"
import { XMarkIcon } from "@heroicons/react/24/solid"
import Navbar from "./Navbar"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  function toggleMenu() {
    setIsOpen(!isOpen)
  }

  return (
    <div
      className={`${
        isOpen ? "h-screen" : "h-[100px]"
      } px-4 py-6 lg:py-0 flex lg:h-[100px]
      flex-col gap-4 lg:flex-row lg:justify-between transition-height duration-500 lg:px-8
      bg-gradient-to-t from-primary to-forth inset-0 z-10 sticky font-comfortaa`}
    >
      <div className="flex justify-between items-center">
        <div className="text-white">
          <p className="text-xl xl:text-2xl">Yasir Elamin</p>
          <p className="whitespace-nowrap xl:text-lg">Web Developer & UX/UI Designer</p>
        </div>
        <div className="lg:hidden">
          <button
            type="button"
            className="block text-gray-500 hover:text-black focus:text-black
              focus:outline-none active:scale-95 active:bg-gray-200
            "
            onClick={toggleMenu}
          >
            {isOpen ? (
              <XMarkIcon
                className="w-10 h-10 text-white border border-solid
              border-white p-1 rounded-md  hover:scale-110 font-bold active:scale-95 active:bg-gray-200"
              />
            ) : (
              <Bars3Icon
                className="w-10 h-10 text-white border border-solid
              border-white p-1 rounded-md  hover:scale-110b active:scale-95 active:bg-gray-200"
              />
            )}
          </button>
        </div>
      </div>
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} toggleMenu={toggleMenu} />
    </div>
  )
}

export default Header
