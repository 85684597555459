import React, { useState, useEffect } from "react"

const PhoneValidator = ({ phoneNumber, setIsValid }) => {
  const [errors, setErrors] = useState({})

  
  useEffect(() => {
    const phoneNumberErrors = validate(phoneNumber)
    setErrors(phoneNumberErrors)
    setIsValid(Object.keys(phoneNumberErrors).length === 0)
  }, [phoneNumber])

  function validate(phone) {
    let validationErrors = {}
    let phoneNumberErrorKeys = []
    const phoneNumberPattern =
      /^(?:\+?1\s?)?\(?(\d{3})\)?[\s.-]?\s?(\d{3})[\s.-]?(\d{4})$/
    if (!phoneNumberPattern.test(phone)) {
      validationErrors.phoneNumber = "Please enter a valid phone number."
      if(phone === "") delete validationErrors.phoneNumber
      phoneNumberErrorKeys.push("phoneInvalid")
    }
    return validationErrors
  }

  return (
    <div>
      {errors.phoneNumber ? (
        <p className="text-red-500">{errors.phoneNumber}</p>
      ) : null}
    </div>
  )
}

export default PhoneValidator
