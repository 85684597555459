import { useState } from "react"
import { Auth } from "aws-amplify"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../context/AuthContext"
import SignUpImage from "../images/svgs/signUp.svg"
import { motion } from "framer-motion"
import EmptyFieldValidator from "../components/form Validators/EmptyFieldValidator"
import EmailValidator from "../components/form Validators/EmailValidator"
import PasswordValidator from "../components/form Validators/PasswordValidator"
import ConfirmPasswordValidator from "../components/form Validators/ConfirmPasswordValidator"
import { EyeIcon } from "@heroicons/react/24/solid"
import { EyeSlashIcon } from "@heroicons/react/24/solid"
import Spinner from "../components/Spinner"

const SignUp = () => {
  const navigate = useNavigate()
  const { setUser } = useAuthContext()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const [isValidUsername, setIsValidUsername] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [isLoading, setIsisLoading] = useState(false)
  const [notification, setNotification] = useState("")
  const [isValidConfirmedPassword, setIsValidConfirmedPassword] =
    useState(false)
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    password: "",
  })

  function handleChange(ev) {
    const { name, value } = ev.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  function handleConfirmPasswordChange(ev) {
    setConfirmPassword(ev.target.value)
  }

  async function handleSubmit(ev) {
    ev.preventDefault()
    setIsSubmitted(true)
    if (
      (formData.userName !== "" &&
        formData.email !== "" &&
        formData.password !== "" &&
        confirmPassword !== "") ||
      (isValidUsername &&
        isValidEmail &&
        isValidPassword &&
        isValidConfirmedPassword)
    ) {
      try {
        setIsisLoading(true)
        const { user } = await Auth.signUp({
          username: formData.userName,
          password: formData.password,
          attributes: {
            email: formData.email,
          },
        })
        setUser(user)
        setIsSubmitted(false)
        setIsisLoading(false)
        navigate("/userValidation")
      } catch (error) {
        setNotification(error.message)
        setIsisLoading(false)
      }
    }
  }

  function handleLogin() {
    navigate("/signin")
  }

  return (
    <>
      {isLoading ? (
        <Spinner text={"Signing Up"} />
      ) : (
        <div className="container flex flex-col items-center grow font-comfortaa mb-4 overflow-x-hidden">
          <h1 className="text-2xl lg:text-3xl mt-8 mb-4 text-primary font-bold self-start">
            Sign Up
          </h1>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 3 }}
          >
            <motion.div
              initial={{ scale: 1, rotate: 0 }}
              animate={{ scale: 1.2, rotate: [5, -5, 5, -5, 0] }}
              transition={{ delay: 0.2 }}
              className="mb-16"
            >
              <img src={SignUpImage} alt="card Image" />
            </motion.div>
            <form
              noValidate
              onSubmit={handleSubmit}
              className="w-full lg:w-[850px] px-6 py-8 rounded-lg shadow-lg self-center mb-16"
            >
              <div className="ml-4 mb-8 flex flex-col gap-6">
                <div>
                  <input
                    type="text"
                    id="user-name"
                    name="userName"
                    value={formData.userName}
                    placeholder="User Name"
                    onChange={handleChange}
                    autoComplete="off"
                    className="w-full py-1 px-2 border-b text-forth focus:outline-forth"
                  />
                  {isSubmitted && (
                    <EmptyFieldValidator
                      fieldType={"Username"}
                      content={formData.userName}
                      setIsValid={setIsValidUsername}
                    />
                  )}
                </div>
                <div>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    placeholder="Email"
                    onChange={handleChange}
                    autoComplete="off"
                    className="w-full py-1 px-2 border-b text-forth focus:outline-forth"
                  />
                  {isSubmitted && (
                    <EmailValidator
                      email={formData.email}
                      setIsValid={setIsValidEmail}
                    />
                  )}
                </div>
                <div>
                  <div className="relative">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      id="password"
                      name="password"
                      value={formData.password}
                      placeholder="Password"
                      onChange={handleChange}
                      autoComplete="new-password"
                      className="w-full py-1 px-2 border-b text-forth focus:outline-forth"
                    />
                    <button
                      type="button"
                      onClick={() => setPasswordVisible(!isPasswordVisible)}
                      className="absolute right-4 top-1/2 -translate-y-1/2"
                    >
                      {isPasswordVisible ? (
                        <EyeSlashIcon className="w-4 text-forth" />
                      ) : (
                        <EyeIcon className="w-4 text-forth" />
                      )}
                    </button>
                  </div>
                  {isSubmitted && (
                    <PasswordValidator
                      password={formData.password}
                      setIsValid={setIsValidPassword}
                    />
                  )}
                </div>
                <div>
                  <div className="relative">
                    <input
                      type={isConfirmPasswordVisible ? "text" : "password"}
                      id="confirm-password"
                      name="confirmPassword"
                      value={confirmPassword}
                      placeholder="Confirm Password"
                      onChange={handleConfirmPasswordChange}
                      autoComplete="new-password"
                      className="w-full py-1 px-2 border-b text-forth focus:outline-forth"
                    />
                    <button
                      type="button"
                      onClick={() =>
                        setConfirmPasswordVisible(!isConfirmPasswordVisible)
                      }
                      className="absolute right-4 top-1/2 -translate-y-1/2 "
                    >
                      {isConfirmPasswordVisible ? (
                        <EyeSlashIcon className="w-4 text-forth" />
                      ) : (
                        <EyeIcon className="w-4 text-forth" />
                      )}
                    </button>
                  </div>
                  {isSubmitted && (
                    <ConfirmPasswordValidator
                      password={formData.password}
                      confirmPassword={confirmPassword}
                      setIsValid={setIsValidConfirmedPassword}
                    />
                  )}
                </div>
              </div>
              <div className="flex gap-12 mt-4 justify-center">
                <button
                  type="submit"
                  className="px-4 py-2 bg-primary text-white rounded font-bold w-[6.5rem] active:scale-95 active:bg-gray-200 hover:scale-105"
                >
                  Sign Up
                </button>
                <button
                  type="button"
                  onClick={handleLogin}
                  className="px-4 py-2 bg-forth text-white rounded font-bold w-[6.5rem] active:scale-95 active:bg-gray-200 hover:scale-105"
                >
                  Sign In
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      )}
    </>
  )
}

export default SignUp
