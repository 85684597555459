const DisplayComments = ({ comments, blogId }) => {
  // Filter comments based on blogId
  const blogComments = comments.filter((comment) => comment.blogId === blogId)

  return (
    <div className="mb-8 font-comfortaa">
      <p className="mb-8 font-bold text-xl lg:text-2xl text-primary">Comments:</p>
      <div className="flex flex-col gap-4 ml-2">
        {blogComments.length > 0 ? (
          blogComments.map((comment) => (
            <div key={comment._id} className="text-forth">
              <p className="font-bold text-lg text-primary">{comment.owner}</p>
              <p>{comment.comment}</p>
            </div>
          ))
        ) : (
          <p className="text-forth lg:text-lg">There are no comments yet</p>
        )}
      </div>
    </div>
  )
}

export default DisplayComments
