import { motion } from "framer-motion"
import { useAuthContext } from "../context/AuthContext"

// *********** Images ********
// UI
import xdLogo from "../images/svgs/xd.svg"
import aiLogo from "../images/svgs/ai.svg"
import psLogo from "../images/svgs/ps.svg"
import figmaLogo from "../images/svgs/figma.svg"
// Web Programming
import htmlLogo from "../images/svgs/html.svg"
import cssLogo from "../images/svgs/css.svg"
import jsLogo from "../images/svgs/js.svg"
import reactLogo from "../images/svgs/react.svg"
import nextjsLogo from "../images/svgs/nextjs.svg"
import tailwindLogo from "../images/svgs/tailwind.svg"
import bootstrapLogo from "../images/svgs/bootstrap.svg"
// Mobile Development
import swiftLogo from "../images/svgs/swift.svg"
import androidLogo from "../images/svgs/android.svg"
import iosLogo from "../images/svgs/ios.svg"
import dartLogo from "../images/svgs/dart.svg"
import flutterLogo from "../images/svgs/flutter.svg"
import kotlinLogo from "../images/svgs/kotlin.svg"
// Backend
import nodejsLogo from "../images/svgs/nodejs.svg"
import mongodbLogo from "../images/svgs/mongodb.svg"
// Serverless and Cloud
import awsLogo from "../images/svgs/aws.svg"
import firebaseLogo from "../images/svgs/firebase.svg"
// Version & Containers
import githubLogo from "../images/svgs/github.svg"
import dockerLogo from "../images/svgs/docker.svg"
// Project Management
import pmpLogo from "../images/pngs/pmp.png"
import pbaLogo from "../images/pngs/pba.png"
import agileLogo from "../images/pngs/agile.png"
//body
import aboutMe from "../images/svgs/aboutMe.svg"
import spark from "../images/svgs/spark.svg"
import tech from "../images/svgs/tech.svg"
import userExperience from "../images/svgs/user.svg"
import together from "../images/svgs/together.svg"
import Modal from "../components/Modal"
import { useState, useEffect } from "react"
import MotionSection from "../components/MotionSection"

const Home = () => {
  const { user } = useAuthContext()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isWelcomed, setIsWelcomed] = useState(false)
  const uiImages = [psLogo, xdLogo, aiLogo, figmaLogo]
  const webImages = [
    htmlLogo,
    cssLogo,
    jsLogo,
    reactLogo,
    nextjsLogo,
    tailwindLogo,
    bootstrapLogo,
  ]
  const mobileImages = [
    iosLogo,
    androidLogo,
    swiftLogo,
    kotlinLogo,
    reactLogo,
    dartLogo,
    flutterLogo,
  ]
  const backImages = [nodejsLogo, mongodbLogo]
  const serverImages = [awsLogo, firebaseLogo]
  const otherImages = [githubLogo, dockerLogo]
  const pmImages = [pmpLogo, agileLogo, pbaLogo]

  const staggeredVariant = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: (index) => ({
      opacity: 1,
      y: 0,
      transition: { delay: 0.09 * index },
    }),
  }
  const paragraphItems = [
    {
      id: 1,
      title: "About Me",
      body: "Hello and welcome to my portfolio! I'm excited to share my journey as a full-stack developer with you. My enthusiasm for technology and software development has led me to explore the world of web and mobile applications, and I can't wait to show you what I've been working on.",
    },
    {
      id: 2,
      title: "A Developer with a Spark",
      body: "I understand that I'm still at the beginning of my career. But that's the exciting part – every day is a chance for me to learn something new and improve my skills. I'm like a sponge, soaking up knowledge about different technologies, coding languages, and development practices. The more I learn, the more I can contribute to exciting projects.",
    },
    {
      id: 3,
      title: "Exploring the Tech Landscape",
      body: "One of the coolest things about being a developer is the opportunity to explore new technologies. I'm like a curious explorer in a rapidly changing landscape. I'm always on the lookout for the latest trends and tools that can help me create better applications. Every new technology I learn is a step forward in my journey.",
    },
    {
      id: 4,
      title: "Building User-Focused Experiences",
      body: "I understand the importance of user-centered design. I want to create applications that people enjoy using – ones that are intuitive, user-friendly, and solve real problems. Every project I work on is a chance to improve my skills and make the user experience better.",
    },
    {
      id: 5,
      title: "Let's Make Together",
      body: "If you're looking for a developer who's excited about technology and eager to contribute, then I'm your person. I'm ready to collaborate, learn, and grow. Whether you have a small project or a big idea, I'm here to help turn concepts into reality.",
    },
    {
      id: 6,
      title: "Bridging Technology and Creativity",
      body: "",
    },
    {
      id: 7,
      title: "UI/UX Design",
      body: "Combining aesthetics with user experience is where I excel. Designing visually engaging interfaces that are both enjoyable and practical is a strength of mine. I'm skilled in using tools like Adobe Photoshop, Adobe XD, Adobe Illustrator, Figma, and have experience in wireframing and prototyping.",
      images: [psLogo, xdLogo, aiLogo, figmaLogo],
    },
    {
      id: 8,
      title: "Web & Front-End Development",
      body: "From transforming static designs into interactive web experiences to optimizing user interfaces for responsiveness, I thrive in the world of web and front-end development. My toolkit features HTML, CSS, JavaScript, as well as frameworks like React, Next.js, Tailwind CSS and Bootstrap. I also dabble in Flutter and Dart to bring creativity to mobile interfaces.",
      images: [
        htmlLogo,
        cssLogo,
        jsLogo,
        reactLogo,
        nextjsLogo,
        tailwindLogo,
        bootstrapLogo,
      ],
    },
    {
      id: 9,
      title: "Mobile App Development",
      body: "I'm passionate about bringing ideas to life in the mobile realm. Proficient in React Native, Swift, Kotlin, and experienced in both iOS (Swift, Objective-C) and Android (Kotlin), I create native and cross-platform apps. From sleek Swift-based iOS solutions to user-friendly Kotlin-driven Android apps and versatile React Native projects, my goal is seamless functionality across platforms. My Dart and Flutter expertise adds visual appeal and performance. Your concepts will flourish in mobile apps that exceed expectations and engage diverse users.",
      images: [
        iosLogo,
        androidLogo,
        swiftLogo,
        kotlinLogo,
        reactLogo,
        dartLogo,
        flutterLogo,
      ],
    },
    {
      id: 10,
      title: "Back-End Development",
      body: "Bridging the gap between user interfaces and databases is where my back-end expertise comes in. I wield the power of Node.js and have a knack for handling MongoDB and JSON data. Building REST APIs is second nature to me, ensuring smooth communication between front-end and back-end systems.",
      images: [nodejsLogo, mongodbLogo],
    },
    {
      id: 11,
      title: "Serverless and Cloud",
      body: "Embracing the cloud revolution, I utilize AWS services like IAM, Amplify, Cognito, API Gateway, and Lambda to build scalable, serverless applications. I'm also familiar with CloudFormation for infrastructure management and Google Firebase for creating dynamic and responsive apps.",
      images: [awsLogo, firebaseLogo],
    },
    {
      id: 12,
      title: "Other Technologies",
      body: "Version control is at the heart of my workflow, with Git and GitHub being integral to how I collaborate and manage code. I'm also well-versed in Docker containers, enabling me to package applications consistently across different environments.",
      images: [githubLogo, dockerLogo],
    },
    {
      id: 13,
      title: "Project Management & Business Analysis",
      body: "I understand that technology is just one piece of the puzzle. That's why I'm skilled in project management methodologies such as Agile and the Waterfall approach. Effective stakeholder management, problem solving, and troubleshooting are my allies in delivering successful projects. I'm also proud of my strong communication and collaboration skills, essential for teamwork and project success.",
      images: [pmpLogo, pbaLogo, agileLogo],
    },
  ]

  useEffect(() => {
    if (user) {
      setModalOpen(true)
    }
  }, [user])

  useEffect(() => {
    const isWelcomedValue = localStorage.getItem("isWelcomed")
    if (isWelcomedValue) setIsWelcomed(JSON.parse(isWelcomedValue))
  }, [])

  const viewportWidth = typeof window !== "undefined" ? window.innerWidth : 0

  return (
    <div className=" font-comfortaa overflow-x-hidden overflow-y-hidden">
      {user && !isWelcomed ? (
        <Modal
          text={`Welcome ${user.username}`}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      ) : null}
      {/* Hero Banner */}
      <div className="relative flex justify-center shadow-md">
        <img
          src={require("../images/banner.png")}
          alt="Yasir Elamin portfolio picture"
          className="h-banner object-cover object-left-top w-screen"
        />
        <div className="absolute flex flex-col gap-2 items-center mt-80 lg:mt-96">
          <p className="whitespace-nowrap text-3xl md:text-5xl lg:text-6xl text-primary font-bold animate-slideFromBottom drop-shadow-xl ">
            Yasir Elamin
          </p>
          <p className="whitespace-nowrap text-forth font-bold text-lg md:text-2xl lg:text-3xl -translate-x-[100rem] animate-slideFromLeft drop-shadow-xl">
            Web Developer & UX/UI Designer
          </p>
        </div>
      </div>
      {/* {Body} */}
      <div className="lg:grid lg:grid-cols-2 xl:grid-cols-3">
        <div className="py-12 lg:col-span-full lg:flex flex-col items-center mt-8">
          <div className="container lg:text-center lg:mt-8">
            <motion.h2
              className="text-primary font-bold text-2xl md:text-3xl lg:text-5xl lg:mt-16"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
            >
              Hello and welcome to my portfolio!
            </motion.h2>
          </div>
          <motion.div
            className="mb-8 md:mb-12"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
          >
            <img src={aboutMe} alt="About Me" className="shadow-xl" />
          </motion.div>
          <div className="container lg:text-center">
            <MotionSection
              initialProps={{ x: viewportWidth }}
              animateProps={{ x: 0 }}
              transition={{
                duration: 0.3,
                stiffness: 120,
                type: "spring",
                delay: 0.5,
              }}
            >
              <p className="text-forth text-lg lg:text-2xl">
                I'm excited to share my journey as a full-stack developer with
                you. My enthusiasm for technology and software development has
                led me to explore the world of web and mobile applications, and
                I can't wait to show you what I've been working on.
              </p>
            </MotionSection>
          </div>
        </div>
        <div className="py-12 bg-gray-50 ">
          <div className="container lg:text-center">
            <motion.h2
              className="text-primary font-bold text-2xl md:text-3xl"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
            >
              A Developer with a Spark
            </motion.h2>
          </div>
          <motion.div
            className="mb-8 md:mb-12"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
          >
            <img src={spark} alt="About Me" className="shadow-xl" />
          </motion.div>
          <div className="container lg:text-center">
            <MotionSection
              initialProps={{ x: -viewportWidth }}
              animateProps={{ x: 0 }}
              transition={{
                duration: 0.3,
                stiffness: 120,
                type: "spring",
                delay: 0.5,
              }}
            >
              <p className="text-forth text-lg lg:text-2xl">
                I understand that I'm still at the beginning of my career. But
                that's the exciting part – every day is a chance for me to learn
                something new and improve my skills. I'm like a sponge, soaking
                up knowledge about different technologies, coding languages, and
                development practices. The more I learn, the more I can
                contribute to exciting projects.
              </p>
            </MotionSection>
          </div>
        </div>
        <div className="py-12 ">
          <div className="container lg:text-center">
            <motion.h2
              className="text-primary font-bold text-2xl md:text-3xl"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
            >
              Exploring the Tech Landscape
            </motion.h2>
          </div>
          <motion.div
            className="mb-8 md:mb-12"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
          >
            <img src={tech} alt="About Me" className="shadow-xl" />
          </motion.div>
          <div className="container lg:text-center">
            <MotionSection
              initialProps={{ x: viewportWidth }}
              animateProps={{ x: 0 }}
              transition={{
                duration: 0.3,
                stiffness: 120,
                type: "spring",
                delay: 0.5,
              }}
            >
              <p className="text-forth text-lg lg:text-2xl">
                One of the coolest things about being a developer is the
                opportunity to explore new technologies. I'm like a curious
                explorer in a rapidly changing landscape. I'm always on the
                lookout for the latest trends and tools that can help me create
                better applications. Every new technology I learn is a step
                forward in my journey.
              </p>
            </MotionSection>
          </div>
        </div>
        <div className="py-12 bg-gray-50 lg:bg-white xl:bg-gray-50">
          <div className="container lg:text-center">
            <motion.h2
              className="text-primary font-bold text-2xl md:text-3xl"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
            >
              Building User Experiences
            </motion.h2>
          </div>
          <motion.div
            className="mb-8 md:mb-12"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
          >
            <img src={userExperience} alt="About Me" className="shadow-xl" />
          </motion.div>
          <div className="container lg:text-center">
            <MotionSection
              initialProps={{ x: -viewportWidth }}
              animateProps={{ x: 0 }}
              transition={{
                duration: 0.3,
                stiffness: 120,
                type: "spring",
                delay: 0.5,
              }}
            >
              <p className="text-forth text-lg lg:text-2xl">
                I understand the importance of user-centered design. I want to
                create applications that people enjoy using – ones that are
                intuitive, user-friendly, and solve real problems. Every project
                I work on is a chance to improve my skills and make the user
                experience better.
              </p>
            </MotionSection>
          </div>
        </div>
        <div className="py-12 lg:bg-gray-50 xl:col-span-full xl:bg-white">
          <div className="container lg:text-center">
            <motion.h2
              className="text-primary font-bold text-2xl md:text-3xl"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
            >
              Let's Make Together
            </motion.h2>
          </div>
          <motion.div
            className="xl:w-screen mb-8 md:mb-12"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
          >
            <img
              src={together}
              alt="About Me"
              className="shadow-xl xl:mx-auto"
            />
          </motion.div>
          <div className="container lg:text-center">
            <MotionSection
              initialProps={{ x: viewportWidth }}
              animateProps={{ x: 0 }}
              transition={{
                duration: 0.3,
                stiffness: 120,
                type: "spring",
                delay: 0.5,
              }}
            >
              <p className="text-forth text-lg lg:text-2xl">
                If you're looking for a developer who's excited about technology
                and eager to contribute, then I'm your person. I'm ready to
                collaborate, learn, and grow. Whether you have a small project
                or a big idea, I'm here to help turn concepts into reality.
              </p>
            </MotionSection>
          </div>
        </div>
      </div>
      <div className="bg-forth">
        <motion.h2
          className="text-white font-bold text-2xl md:text-3xl lg:text-5xl lg:mb-8 text-center py-8 lg:py-24 shadow-2xl lg:text-center mb-8"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.8 }}
          viewport={{ once: true }}
        >
          Bridging Technology and Creativity
        </motion.h2>
      </div>
      <div className="xl:grid xl:grid-cols-2 flex flex-col">
        <div className=" xl:col-span-full py-8">
          <div className="container lg:text-center">
            <motion.h2
              className="text-primary font-bold text-2xl md:text-3xl mb-4 "
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
            >
              UI/UX Design
            </motion.h2>
          </div>
          <div className="container lg:text-center">
            <motion.p
              className="text-forth text-lg"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
              viewport={{ once: true }}
            >
              Combining aesthetics with user experience is where I excel.
              Designing visually engaging interfaces that are both enjoyable and
              practical is a strength of mine. I'm skilled in using tools like
              Adobe Photoshop, Adobe XD, Adobe Illustrator, Figma, and have
              experience in wireframing and prototyping.
            </motion.p>
          </div>
          <motion.div
            className="container lg:text-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
          >
            <div className="flex flex-wrap justify-around my-8 ">
              {uiImages.map((image, index) => (
                <motion.div
                  key={index}
                  variants={staggeredVariant}
                  initial="initial"
                  whileInView="animate"
                  viewport={{
                    once: true,
                  }}
                  custom={index}
                >
                  <img
                    src={image}
                    alt="skills"
                    className="drop-shadow-xl w-20"
                  />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
        <div className=" bg-gray-50 py-8 ">
          <div className="container lg:text-center">
            <motion.h2
              className="text-primary font-bold text-2xl md:text-3xl mb-4 "
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
            >
              Web & Front-End Development
            </motion.h2>
          </div>
          <div className="container lg:text-center">
            <motion.p
              className="text-forth text-lg"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
              viewport={{ once: true }}
            >
              From transforming static designs into interactive web experiences
              to optimizing user interfaces for responsiveness, I thrive in the
              world of web and front-end development. My toolkit features HTML,
              CSS, JavaScript, as well as frameworks like React, Next.js,
              Tailwind CSS and Bootstrap. I also dabble in Flutter and Dart to
              bring creativity to mobile interfaces.
            </motion.p>
          </div>
          <motion.div
            className="container lg:text-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
          >
            <div className="flex flex-wrap justify-around my-8 gap-y-4">
              {webImages.map((image, index) => (
                <motion.div
                  key={index}
                  variants={staggeredVariant}
                  initial="initial"
                  whileInView="animate"
                  viewport={{
                    once: true,
                  }}
                  custom={index}
                >
                  <img
                    src={image}
                    alt="skills"
                    className="drop-shadow-xl w-20"
                  />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
        <div className="py-8">
          <div className="container lg:text-center">
            <motion.h2
              className="text-primary font-bold text-2xl md:text-3xl mb-4 "
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
            >
              Mobile App Development
            </motion.h2>
          </div>
          <div className="container lg:text-center">
            <motion.p
              className="text-forth text-lg"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
              viewport={{ once: true }}
            >
              I'm passionate about bringing ideas to life in the mobile realm.
              Proficient in React Native, Swift, Kotlin, and experienced in both
              iOS (Swift, Objective-C) and Android (Kotlin), I create native and
              cross-platform apps. From sleek Swift-based iOS solutions to
              user-friendly Kotlin-driven Android apps and versatile React
              Native projects, my goal is seamless functionality across
              platforms. My Dart and Flutter expertise adds visual appeal and
              performance. Your concepts will flourish in mobile apps that
              exceed expectations and engage diverse users.
            </motion.p>
          </div>
          <motion.div
            className="container lg:text-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
          >
            <div className="flex flex-wrap justify-around my-8 gap-y-4">
              {mobileImages.map((image, index) => (
                <motion.div
                  key={index}
                  variants={staggeredVariant}
                  initial="initial"
                  whileInView="animate"
                  viewport={{
                    once: true,
                  }}
                  custom={index}
                >
                  <img
                    src={image}
                    alt="skills"
                    className="drop-shadow-xl w-20"
                  />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
        <div className="py-8 bg-gray-50 xl:bg-white">
          <div className="container lg:text-center">
            <motion.h2
              className="text-primary font-bold text-2xl md:text-3xl mb-4 "
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
            >
              Back-End Development
            </motion.h2>
          </div>
          <div className="container lg:text-center">
            <motion.p
              className="text-forth text-lg"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
              viewport={{ once: true }}
            >
              Bridging the gap between user interfaces and databases is where my
              back-end expertise comes in. I wield the power of Node.js and have
              a knack for handling MongoDB and JSON data. Building REST APIs is
              second nature to me, ensuring smooth communication between
              front-end and back-end systems.
            </motion.p>
          </div>
          <motion.div
            className="container lg:text-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
          >
            <div className="flex flex-wrap justify-around my-8 gap-y-4">
              {backImages.map((image, index) => (
                <motion.div
                  key={index}
                  variants={staggeredVariant}
                  initial="initial"
                  whileInView="animate"
                  viewport={{
                    once: true,
                  }}
                  custom={index}
                >
                  <img
                    src={image}
                    alt="skills"
                    className="drop-shadow-xl w-20"
                  />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
        <div className="py-8 xl:bg-gray-50 ">
          <div className="container lg:text-center">
            <motion.h2
              className="text-primary font-bold text-2xl md:text-3xl mb-4 "
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
            >
              Serverless and Cloud
            </motion.h2>
          </div>
          <div className="container lg:text-center">
            <motion.p
              className="text-forth text-lg"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
              viewport={{ once: true }}
            >
              Embracing the cloud revolution, I utilize AWS services like IAM,
              Amplify, Cognito, API Gateway, and Lambda to build scalable,
              serverless applications. I'm also familiar with CloudFormation for
              infrastructure management and Google Firebase for creating dynamic
              and responsive apps.
            </motion.p>
          </div>
          <motion.div
            className="container lg:text-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
          >
            <div className="flex flex-wrap justify-around my-8 gap-y-4">
              {serverImages.map((image, index) => (
                <motion.div
                  key={index}
                  variants={staggeredVariant}
                  initial="initial"
                  whileInView="animate"
                  viewport={{
                    once: true,
                  }}
                  custom={index}
                >
                  <img
                    src={image}
                    alt="skills"
                    className="drop-shadow-xl w-20"
                  />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
        <div className="py-8 bg-gray-50 ">
          <div className="container lg:text-center">
            <motion.h2
              className="text-primary font-bold text-2xl md:text-3xl mb-4 "
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
            >
              Other Technologies
            </motion.h2>
          </div>
          <div className="container lg:text-center">
            <motion.p
              className="text-forth text-lg"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              Version control is at the heart of my workflow, with Git and
              GitHub being integral to how I collaborate and manage code. I'm
              also well-versed in Docker containers, enabling me to package
              applications consistently across different environments.
            </motion.p>
          </div>
          <motion.div
            className="container lg:text-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
          >
            <div className="flex flex-wrap justify-around my-8 gap-y-4">
              {otherImages.map((image, index) => (
                <motion.div
                  key={index}
                  variants={staggeredVariant}
                  initial="initial"
                  whileInView="animate"
                  viewport={{
                    once: true,
                  }}
                  custom={index}
                >
                  <img
                    src={image}
                    alt="skills"
                    className="drop-shadow-xl w-20"
                  />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
        <div className="py-8">
          <div className="container lg:text-center">
            <motion.h2
              className="text-primary font-bold text-2xl md:text-3xl mb-4 "
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
            >
              Project Management & Business Analysis
            </motion.h2>
          </div>
          <div className="container lg:text-center">
            <motion.p
              className="text-forth text-lg"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
              viewport={{ once: true }}
            >
              I understand that technology is just one piece of the puzzle.
              That's why I'm skilled in project management methodologies such as
              Agile and the Waterfall approach. Effective stakeholder
              management, problem solving, and troubleshooting are my allies in
              delivering successful projects. I'm also proud of my strong
              communication and collaboration skills, essential for teamwork and
              project success.
            </motion.p>
          </div>
          <motion.div
            className="container lg:text-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
          >
            <div className="flex flex-wrap justify-around my-8 gap-y-4">
              {pmImages.map((image, index) => (
                <motion.div
                  key={index}
                  variants={staggeredVariant}
                  initial="initial"
                  whileInView="animate"
                  viewport={{
                    once: true,
                  }}
                  custom={index}
                >
                  <img
                    src={image}
                    alt="skills"
                    className="drop-shadow-xl w-20"
                  />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Home
