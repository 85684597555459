import React, { useState, useEffect } from "react"

const EmailValidator = ({ email, setIsValid }) => {
  const [errors, setErrors] = useState({})
  const [emailErrorKeys, setEmailErrorKeys] = useState([])

  useEffect(() => {
    const emailErrors = validate(email)
    setErrors(emailErrors)
    setIsValid(Object.keys(emailErrors).length === 0)
  }, [email])

  function validate(email) {
    let validationErrors = {}
    let emailErrorKeys = []
    if (email.trim() === "") {
      validationErrors.emailRequired = "Email Address is required"
      emailErrorKeys.push("emailRequired")
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      validationErrors.emailInvalid = "Invalid Email Address Format"
      emailErrorKeys.push("emailInvalid")
    }
    setEmailErrorKeys(emailErrorKeys)
    return validationErrors
  }

  return (
    <div>
      {emailErrorKeys.map((errorKey) =>
        errors[errorKey] ? (
          <p key={errorKey} className="text-red-500">
            {errors[errorKey]}
          </p>
        ) : null
      )}
    </div>
  )
}

export default EmailValidator
