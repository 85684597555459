import { NavLink, useParams } from "react-router-dom"
import { BookOpenIcon } from "@heroicons/react/24/outline"
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline"
import DisplayComments from "../components/DisplayComments"
import CommentsForm from "../components/CommentsForm"
import { useAuthContext } from "../context/AuthContext"
import { useState, useEffect } from "react"
import Spinner from "../components/Spinner"
import Modal from "../components/Modal"

const Blog = () => {
  const [blogs, setBlogs] = useState([])
  const [comments, setComments] = useState([])
  const [upvotes, setUpvotes] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { isAuthenticated, user } = useAuthContext()
  const [isModalOpen, setModalOpen] = useState(false)
  const [modalText, setModalText] = useState("")
  const [spinnerText, setSpinnerText] = useState("LoadingBlog")

  useEffect(() => {
    fetch(
      "https://0g4pkboib3.execute-api.us-east-1.amazonaws.com/default/lambdagetblogs-dev"
    )
      .then((res) => res.json())
      .then((data) => data.documents)
      .then((blogs) => {
        return fetch(
          "https://bejels2kpd.execute-api.us-east-1.amazonaws.com/default/lambdagetcomments-dev"
        )
          .then((res) => res.json())
          .then((data) => {
            return {
              blogs,
              comments: data.documents,
            }
          })
      })
      .then(({ blogs, comments }) => {
        return fetch(
          "https://mafmglv91f.execute-api.us-east-1.amazonaws.com/default/lambdagetupvotes-dev"
        )
          .then((res) => res.json())
          .then((data) => {
            return {
              blogs,
              comments,
              upVotes: data.documents,
            }
          })
      })
      .then(({ blogs, comments, upVotes }) => {
        setBlogs(blogs)
        setComments(comments)
        setUpvotes(upVotes)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error.message)
        setIsLoading(false)
      })
  }, [])

  const { blogId } = useParams()
  let blog
  if (blogs && blogs.length > 0) {
    blog = blogs.find((blog) => blog._id === blogId)
  }

  let blogUpvotes = upvotes?.find((upvote) => upvote.blog === blogId)
  let numberOfVoters = blogUpvotes ? blogUpvotes.upVoters?.length : 0

  async function handleUpvote() {
    if (!isAuthenticated) {
      setModalText("Please Sign In or Sign Up to upvote")
      setModalOpen(true)
      return
    }

    let isBlogUpvotesExist = upvotes?.some((upvote) => upvote.blog === blogId)

    if (isBlogUpvotesExist) {
      let alreadyVoted = upvotes?.some((upvote) => {
        if (upvote.blog === blogId) {
          return upvote.upVoters?.some((voter) => voter === user.username)
        }
        return false
      })

      if (alreadyVoted) {
        setModalText("Thank you but you have already voted")
        setModalOpen(true)
        return
      } else {
        // Update the Upvotes if already existing
        try {
          const blogUpvotes = upvotes?.find((upvote) => upvote.blog === blogId)
          const currentVoters = blogUpvotes.upVoters
          const updatedUpvoters = [...currentVoters, user.username]
          setSpinnerText("Posting Your Upvote")
          setIsLoading(true)
          const options = {
            method: "POST",
            headers: {
              "Access-Control-Request-Headers": "*",
            },
            body: JSON.stringify({ blog: blog._id, upVoters: updatedUpvoters }),
          }
          const response = await fetch(
            "https://px0x60qtjc.execute-api.us-east-1.amazonaws.com/default/lambdaupdateupvote-dev",
            options
          )
          if (response.ok) {
            setIsLoading(false)
            window.location.reload()
          } else {
            console.error("Error sending Upvote")
            setIsLoading(false)
          }
        } catch (error) {
          console.error(error)
          setIsLoading(false)
        }
      }
    } else {
      // create the new upvote
      try {
        setSpinnerText("Posting Your Upvote")
        setIsLoading(true)
        const options = {
          method: "POST",
          headers: {
            "Access-Control-Request-Headers": "*",
          },
          body: JSON.stringify({ blog: blog._id, upVoters: [user.username] }),
        }
        const response = await fetch(
          "https://o0f75k7lc0.execute-api.us-east-1.amazonaws.com/default/lambdapostupvote-dev",
          options
        )
        if (response.ok) {
          setIsLoading(false)
          window.location.reload()
        } else {
          console.error("Error sending Upvote")
          setIsLoading(false)
        }
      } catch (error) {
        console.error(error)
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner text={spinnerText} />
      ) : (
        <div className="mt-8 container font-comfortaa overflow-x-hidden">
          <Modal
            text={modalText}
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
          />
          <p className="font-bold text-2xl lg:text-4xl lg:my-8 mb-8 text-center text-primary">
            {blog.title}
          </p>
          <img
            src={blog.cover}
            alt="Blog Cover"
            className="mb-4 rounded lg:rounded-lg shadow-xl"
          />
          <div className="flex justify-center text-forth font-bold lg:text-lg">
            <p>{new Date(blog.date).toDateString()}</p>
            <div className="flex gap-2 mb-4">
              <p className="mr-2">-</p>
              <BookOpenIcon className="w-4" />
              {blog.readingTime}
            </div>
          </div>
          <div className="flex flex-col text-primary">
            <p className="mb-4 font-bold lg:text-lg">
              This blog has {numberOfVoters} upvote(s)
            </p>
            <button
              type="button"
              onClick={handleUpvote}
              className="px-4 py-2 bg-forth text-white rounded font-bold w-[6.5rem] mb-8 active:scale-95 active:bg-gray-200 hover:scale-105"
            >
              Upvote
            </button>
          </div>
          {blog.body.map((item) => (
            <div key={item.id} className="flex flex-col">
              <p className="font-bold text-xl text-primary">{item.title}</p>
              {item.image && (
                <img
                  src={item.image}
                  alt="Paragraph Image"
                  className="mt-4 mb-8 rounded shadow"
                />
              )}
              {item.paragraph && <p>{item.paragraph}</p>}
              <div>
                {item.paragraphs?.map((item, index) => (
                  <div key={index}>
                    <p className="text-forth lg:text-xl">{item}</p>
                    <br />
                  </div>
                ))}
              </div>
            </div>
          ))}
          <CommentsForm blog={blog} />
          <DisplayComments comments={comments} blogId={blog._id} />
          <div className="mb-4 flex gap-2">
            <NavLink
              to="/blogs"
              className="flex gap-2 text-forth font-bold mb-8 hover:scale-105"
            >
              <ArrowSmallLeftIcon className="w-4" />
              Go back to blogs List
            </NavLink>
          </div>
        </div>
      )}
    </>
  )
}

export default Blog
