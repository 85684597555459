import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

function MotionSection({
  children,
  initialProps,
  animateProps,
  ...motionProps
}) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  return (
    <div ref={ref}>
      <motion.div
        initial={initialProps}
        animate={inView ? animateProps : {}}
        {...motionProps}
      >
        {children}
      </motion.div>
    </div>
  )
}

export default MotionSection
