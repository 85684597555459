import {
  Outlet,
  ScrollRestoration,
  createBrowserRouter,// This to replace later
  createHashRouter
} from "react-router-dom"

import Home from "./pages/Home"
import Portfolio from "./pages/Portfolio"
import BlogList from "./pages/BlogList"
import Blog from "./pages/Blog"
import Contact from "./pages/Contact"
import SignUp from "./pages/SignUp"
import SignIn from "./pages/SignIn"
import Error404Page from "./pages/Error404Page"
import Header from "./components/Header"
import Footer from "./components/Footer"
import SignUpValidation from "./pages/SignUpValidation"
import AdminContact from "./pages/AdminContact"
import ForgetPassword from "./pages/ForgetPassword"
import SendCode from "./pages/SendCode"
import TodoList from "./projects/todo-list/src/TodoListApp"
import CurrencyConverter from "./projects/currency-converter/src/CurrencyConverterApp"

export const router = createHashRouter([
  {
    element: <NavLayout />,
    children: [
      { path: "*", element: <Error404Page /> },
      { path: "/", element: <Home /> },
      { path: "/portfolio", element: <Portfolio /> },
      {
        path: "/blogs",
        children: [
          {
            index: true,
            element: <BlogList />,
          },
          {
            path: ":blogId",
            element: <Blog />,
          },
        ],
      },
      { path: "/contact", element: <Contact /> },
      { path: "/signUp", element: <SignUp /> },
      { path: "/userValidation", element: <SignUpValidation /> },
      { path: "/signIn", element: <SignIn /> },
      { path: "/adminContact", element: <AdminContact /> },
      { path: "/forgotPassword", element: <ForgetPassword /> },
      { path: "/sendCode", element: <SendCode /> },
      { path: "/projects/todo-list", element: <TodoList /> },
      { path: "/projects/currency-converter", element: <CurrencyConverter /> },
    ],
  },
])

function NavLayout() {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <Outlet />
      <Footer />
      <ScrollRestoration />
    </div>
  )
}
