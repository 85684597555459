import { NavLink } from "react-router-dom"
import { HomeIcon } from "@heroicons/react/24/solid"
import { ArchiveBoxIcon } from "@heroicons/react/24/solid"
import { EnvelopeIcon } from "@heroicons/react/24/solid"
import { ClipboardDocumentIcon } from "@heroicons/react/24/solid"
import { UserIcon } from "@heroicons/react/24/solid"
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/solid"
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/solid"
import { useAuthContext } from "../context/AuthContext"
import { Auth } from "aws-amplify"
import { useNavigate } from "react-router-dom"

const Navbar = ({ isOpen, setIsOpen, toggleMenu }) => {
  const { isAuthenticated, setIsAuthenticated, user, setUser } =
    useAuthContext()

  const navigate = useNavigate()

  async function handleLogOut() {
    await Auth.signOut()
    setIsAuthenticated(false)
    setUser("")
    navigate("/")
    setIsOpen(false)
    localStorage.removeItem("isWelcomed")
  }

  return (
    <nav
      className={`
        ${isOpen ? "visible" : "invisible lg:visible"}
        transition-all duration-200 flex flex-col lg:flex-row justify-between lg:justify-start
        lg:gap-4 gap-10`}
    >
      <ul className="flex flex-col gap-6 lg:flex-row lg:gap-6 lg:items-end lg:pb-8 text-white mt-8 ml-2">
        <li onClick={toggleMenu}>
          <NavLink to={"/"} className="flex gap-2 items-center ">
            <HomeIcon className="w-6 lg:w-4 xl:w-6" />
            <p className="text-lg lg:text-base xl:text-lg font-bold lg:font-normal active:scale-95 active:text-gray-200">
              Home
            </p>
          </NavLink>
        </li>
        <li onClick={toggleMenu}>
          <NavLink to={"/portfolio"} className="flex gap-2 ">
            <ArchiveBoxIcon className="w-6 lg:w-4 xl:w-6" />
            <p className="text-lg lg:text-base xl:text-lg font-bold lg:font-normal active:scale-95 active:text-gray-200">
              Portfolio
            </p>
          </NavLink>
        </li>
        <li onClick={toggleMenu}>
          <NavLink to={"/blogs"} className="flex gap-2 ">
            <ClipboardDocumentIcon className="w-6 lg:w-4 xl:w-6" />
            <p className="text-lg lg:text-base xl:text-lg font-bold lg:font-normal active:scale-95 active:text-gray-200">
              Blogs
            </p>
          </NavLink>
        </li>
        {user && <li className="hidden lg:block">Welcome: {user.username}</li>}
      </ul>
      <ul className="flex flex-col gap-4 lg:flex-row lg:gap-6 lg:mt-8 lg:items-end lg:pb-8 text-white ml-2">
        <li onClick={toggleMenu}>
          <NavLink to={"/contact"} className="flex gap-2 ">
            <EnvelopeIcon className="w-6 lg:w-4 xl:w-6" />
            <p className="text-lg lg:text-base xl:text-lg active:scale-95 active:text-gray-200">
              Contact
            </p>
          </NavLink>
        </li>
        {!isAuthenticated && (
          <li onClick={toggleMenu}>
            <NavLink to={"/signUp"} className="flex gap-2 ">
              <UserIcon className="w-6 lg:w-4 xl:w-6" />
              <p className="text-lg lg:text-base xl:text-lg whitespace-nowrap active:scale-95 active:text-gray-200">
                Sign Up
              </p>
            </NavLink>
          </li>
        )}
        {isAuthenticated ? (
          <li onClick={handleLogOut}>
            <NavLink to={"*"} className="flex gap-2 ">
              <ArrowRightOnRectangleIcon className="w-6 lg:w-4 xl:w-6" />
              <p className="text-lg lg:text-base xl:text-lg whitespace-nowrap active:scale-95 active:text-gray-200">
                Sign Out
              </p>
            </NavLink>
          </li>
        ) : (
          <li onClick={toggleMenu}>
            <NavLink to={"/signIn"} className="flex gap-2 ">
              <ArrowLeftOnRectangleIcon className="w-6 lg:w-4 xl:w-6" />
              <p className="text-lg lg:text-base xl:text-lg whitespace-nowrap active:scale-95 active:text-gray-200">
                Sign In
              </p>
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default Navbar
