import {NavLink } from "react-router-dom"
import errorImage from "../images/svgs/fourofour.svg"
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline"

function Error404Page() {
  return (
    <main className="font-comfortaa overflow-x-hidden overflow-y-hidden container flex flex-col items-center gap-6 flex-grow mb-8">
      <section className="h-[50%]">
        <img src={errorImage} alt="404image" />
      </section>
      <section className="flex flex-col items-center gap-2">
        <h1 className="text-primary lg:text-2xl">Oops! Page Not Found 🤔</h1>
        <h2 className="text-red-500 font-bold text-lg lg:text-2xl">
          Error 404
        </h2>
        <p className="text-forth text-center text-[0.8rem] lg:text-xl">
          I am really sorry, but the page you're looking for doesn't exist or
          has been moved.
        </p>
      </section>
      <section className="flex flex-col items-center gap-4 lg:text-2xl">
        <h3 className="text-primary lg:text-2xl">What might have happened:</h3>
        <ul className="flex flex-col items-center text-forth text-[0.8rem] gap-2 lg:text-2xl">
          <li className="text-center text-[0.8rem] lg:text-xl">
            The URL is incorrect. Double-check it for any typos.
          </li>
          <li className="text-center text-[0.8rem] lg:text-xl">
            The page may have been moved, deleted, or never existed at all.
          </li>
          <li className="text-center text-[0.8rem] lg:text-xl">
            You followed an outdated or broken link.
          </li>
        </ul>
      </section>
      <section className="flex flex-col items-center gap-4 lg:text-2xl">
        <h3 className="text-primary lg:text-2xl">Here's what you can do:</h3>
        <ul className="flex flex-col  gap-4 lg:text-2xl">
          <li className="flex gap-2 text-[0.8rem] text-forth lg:text-xl">
            <ArrowSmallLeftIcon className="lg:w-6 w-4" />
            <NavLink to="/" className="">
              Click to go back to Home
            </NavLink>
          </li>
          <li className="flex gap-2 text-[0.8rem] text-forth lg:text-xl">
            <ArrowSmallLeftIcon className="lg:w-6 w-4" />
            <NavLink to="/portfolio" className="">
              Click to go back to Portfolio
            </NavLink>
          </li>
          <li className="flex gap-2 text-[0.8rem] text-forth lg:text-xl">
            <ArrowSmallLeftIcon className="lg:w-6 w-4" />
            <NavLink to="/blogs" className="">
              Click to go back to blogs
            </NavLink>
          </li>
        </ul>
      </section>
    </main>
  )
}

export default Error404Page
